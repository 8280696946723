import {BrowserRouter as Router} from 'react-router-dom';
import useRoutes from "./routes";
import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import {useEffect} from "react";

function App() {
  const routes = useRoutes();
    useEffect(() => {
        let l = localStorage.getItem("lang");
        if (l === null){
            localStorage.setItem("lang", "ru");
            window.location.reload();
        }
    }, [localStorage]);
  return (
      <div className={"relative"}>
        <Router>
            <Header/>
              <div>
                {routes}
              </div>
            <Footer/>
        </Router>
      </div>
  );
}

export default App;
