import {useEffect} from "react";

export const QRPage = () => {
    const url = window.navigator.userAgent.toLowerCase();
    useEffect(() => {
        if (url.includes("android")){
            window.location.replace("https://play.google.com/store/apps/details?id=com.bekinston.Sorpa");
        }else if(url.includes("iphone") || url.includes("iphone")){
            window.location.replace("https://apps.apple.com/ru/app/sorpa/id6480465302");
        }
    }, []);
    return(
        <div>

        </div>
    )
}
