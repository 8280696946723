import React from "react";

export const BigEmailIcon = (props) => (
    <svg width="100px" height="100px" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" fill="#BDFF00"/>
        <g clipPath="url(#clip0_755_2797)">
            <path d="M35 35H65C65.442 35 65.8659 35.1756 66.1785 35.4882C66.4911 35.8007 66.6666 36.2246 66.6666 36.6667V63.3333C66.6666 63.7754 66.4911 64.1993 66.1785 64.5118C65.8659 64.8244 65.442 65 65 65H35C34.558 65 34.134 64.8244 33.8215 64.5118C33.5089 64.1993 33.3333 63.7754 33.3333 63.3333V36.6667C33.3333 36.2246 33.5089 35.8007 33.8215 35.4882C34.134 35.1756 34.558 35 35 35V35ZM50.1 49.4717L39.4133 40.3967L37.255 42.9367L50.1216 53.8617L62.7566 42.9283L60.5766 40.4067L50.1016 49.4717H50.1Z" fill="#222222"/>
        </g>
        <defs>
            <clipPath id="clip0_755_2797">
                <rect width="40" height="40" fill="white" transform="translate(30 30)"/>
            </clipPath>
        </defs>
    </svg>

);
