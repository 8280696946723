const translations = {
    ru: {
        text1: {
            label: "Добавка",
            color: "#BDFF00"
        },
        text2: {
            label: " к вашему балансу",
            color: "white"
        },
        text3: {
            label: "Оплачивайте балансом с добавкой во всех ваших любимых заведениях Добавляем до 20% к вашему бюджету",
            color: "white"
        },
        text4: {
            label: "Как увеличить свой баланс?",
            color: "black"
        },
        text5: {
            label: "Где можно потратить добавку?",
            color: "black"
        },
        text6: {
            label: "Доступно к скачиванию",
            color: "white"
        },
        text7: {
            label: "Что такое добавка?",
            color: "black"
        },
        text8: {
            label: "Добавка (cash-up) моментальное получение бонусных баллов с увеличением за пополнение счета в мобильном приложении",
            color: "black"
        },
        text9: {
            label: "Получить больше",
            color: "black"
        },
        text10: {
            label: "Позволяет до 20% увеличить свой баланс",
            color: "black"
        },
        text11: {
            label: "Выгода сразу",
            color: "black"
        },
        text12: {
            label: "Добавку можно потратить сразу",
            color: "black"
        },
        text13: {
            label: "Сначала добавка",
            color: "black"
        },
        text14: {
            label: "С вашего счета списывается добавка, затем основной баланс",
            color: "black"
        },
        text15: {
            label: "Процесс пополнения баланса с добавкой",
            color: "black"
        },
        text16: {
            label: "Укажите сумму пополнения",
            color: "#888888"
        },
        text17: {
            label: "Итого на вашем счету:",
            color: "white"
        },
        text18: {
            label: "добавки",
            color: "#BDFF00"
        },
        text19: {
            label: "От",
            color: "white"
        },
        text20: {
            label: "Как пользоваться приложением?",
            color: "black"
        },
        text21: {
            label: "Скачайте приложение",
            color: "black"
        },
        text22: {
            label: "Более 70 наших партнеров уже ждут вас в нашем приложении",
            color: "black"
        },
        text23: {
            label: "Пополните баланс",
            color: "black"
        },
        text24: {
            label: "Получите моментально добавку до 20% и увеличьте свой баланс",
            color: "black"
        },
        text25: {
            label: "Оплатите по QR-коду",
            color: "black"
        },
        text26: {
            label: "Сначала тратится добавка",
            color: "black"
        },
        text27: {
            label: "Где можно оплатить с добавкой?",
            color: "black"
        },
        text28: {
            label: "Кафе и Рестораны",
            color: "white"
        },
        text29: {
            label: "Красота",
            color: "white"
        },
        text30: {
            label: "Здоровье",
            color: "white"
        },
        text31: {
            label: "Спорт",
            color: "white"
        },
        text32: {
            label: "Развлечения",
            color: "white"
        },
        text33: {
            label: "Одежда",
            color: "white"
        },
        text34: {
            label: "Услуги",
            color: "white"
        },
        text35: {
            label: "Финансы и учет",
            color: "white"
        },
        text36: {
            label: "Цветы",
            color: "white"
        },
        text37: {
            label: "Банкетные залы",
            color: "white"
        },
        text38: {
            label: "Туризм",
            color: "white"
        },
        text39: {
            label: "Скачивай приложение и увеличивай баланс вместе с нами",
            color: "white"
        },
    },
    kk: {
        text1: {
            label: "Сіздің балансыңызға",
            color: "white"
        },
        text2: {
            label: "қосымша",
            color: "#BDFF00"
        },
        text3: {
            label: "Барлық сүйікті орындарыңызда балансқа қосымша арқылы төлеңізБюджетіңізді 20%-ға дейін арттырамыз",
            color: "white"
        },
        text4: {
            label: "Балансымды қалай арттыруға болады?",
            color: "black"
        },
        text5: {
            label: "Қосымшаны қайда жұмсауға болады?",
            color: "black"
        },
        text6: {
            label: "Жүктеп алуға қолжетімді",
            color: "white"
        },
        text7: {
            label: "Қосымша дегеніміз не?",
            color: "white"
        },
        text8: {
            label: "Қосымша (cash-up) - Бонус ұпайларын мобильді қосымшада шотты толықтыру арқылы бірден алу",
            color: "black"
        },
        text9: {
            label: "Көбірек алу",
            color: "black"
        },
        text10: {
            label: "Балансыңды 20%-ға дейін арттырады",
            color: "black"
        },
        text11: {
            label: "Жедел пайда",
            color: "black"
        },
        text12: {
            label: "Қосымшаны бірден жұмсауға болады",
            color: "black"
        },
        text13: {
            label: "Алдымен қосымша",
            color: "black"
        },
        text14: {
            label: "Алдымен сіздің шотыңыздан қосымша, содан кейін негізгі баланс алынады",
            color: "black"
        },
        text15: {
            label: "Баланс және қосымшаны толтыру процессі",
            color: "black"
        },
        text16: {
            label: "Толықтыру сомасын көрсетіңіз",
            color: "#888888"
        },
        text17: {
            label: "Шотыңыздағы жалпы сома",
            color: "white"
        },
        text18: {
            label: "қосымша",
            color: "#BDFF00"
        },
        text19: {
            label: "-нан",
            color: "white"
        },
        text20: {
            label: "Мобильді қосымшамен қалай қолданады?",
            color: "black"
        },
        text21: {
            label: "Мобильді қосымшаны жүктеңіз",
            color: "black"
        },
        text22: {
            label: "70-тен астам серіктестеріміз сізді мобильді қосымшамызда күтуде",
            color: "black"
        },
        text23: {
            label: "Балансты толтырыңыз",
            color: "black"
        },
        text24: {
            label: "20%-ға дейін қосымшаңызды бірден алып, балансыңызды арттырыңыз",
            color: "black"
        },
        text25: {
            label: "QR-кодпен төлеңіз",
            color: "black"
        },
        text26: {
            label: "Алдымен қосымша жұмсалынады",
            color: "black"
        },
        text27: {
            label: "Қосымша арқылы қайда төлеуге болады?",
            color: "black"
        },
        text28: {
            label: "Кафе мен Мейрамханалар",
            color: "white"
        },
        text29: {
            label: "Сұлулық",
            color: "white"
        },
        text30: {
            label: "Денсаулық",
            color: "white"
        },
        text31: {
            label: "Спорт",
            color: "white"
        },
        text32: {
            label: "Ойын-сауық",
            color: "white"
        },
        text33: {
            label: "Киім",
            color: "white"
        },
        text34: {
            label: "Қызметтер",
            color: "white"
        },
        text35: {
            label: "Қаржы және есеп",
            color: "white"
        },
        text36: {
            label: "Гүлдер",
            color: "white"
        },
        text37: {
            label: "Банкет залдары",
            color: "white"
        },
        text38: {
            label: "Туризм",
            color: "white"
        },
        text39: {
            label: "Қосымшаны жүктеп ал да, балансынды бізбен бірге арттыр",
            color: "white"
        },
    }
};

export default translations;
