export const AppStoreIcon = () => {
    return(
        <svg width="144" height="48" viewBox="0 0 144 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1738_4961)">
                <mask id="mask0_1738_4961" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="144" height="48">
                    <path d="M143.597 0H0V48H143.597V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_1738_4961)">
                    <mask id="mask1_1738_4961" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="144" height="48">
                        <path d="M143.64 0H0V48H143.64V0Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask1_1738_4961)">
                        <path d="M132.12 0H11.4C11.04 0 10.56 0 10.08 0C9.72 0 9.36 0 9 0C8.16 0 7.44 0.12 6.6 0.24C5.76 0.36 5.04 0.6 4.32 0.96C3.6 1.32 3 1.8 2.4 2.4C1.8 3 1.32 3.6 0.96 4.32C0.6 5.04 0.36 5.88 0.24 6.6C0.12 7.44 0 8.28 0 9C0 9.36 0 9.72 0 10.08C0 10.56 0 10.92 0 11.4V36.48C0 36.96 0 37.32 0 37.8C0 38.16 0 38.52 0 38.88C0 39.72 0.12 40.44 0.24 41.28C0.36 42.12 0.6 42.84 0.96 43.56C1.32 44.4 1.8 45 2.4 45.6C3 46.2 3.6 46.68 4.32 47.04C5.04 47.4 5.76 47.64 6.6 47.76C7.44 47.88 8.16 48 9 48C9.36 48 9.72 48 10.08 48C10.56 48 10.92 48 11.4 48H132.12C132.6 48 132.96 48 133.44 48C133.8 48 134.16 48 134.52 48C135.36 48 136.08 47.88 136.92 47.76C137.76 47.64 138.48 47.4 139.2 47.04C139.92 46.68 140.52 46.2 141.12 45.6C141.72 45 142.2 44.4 142.56 43.68C142.92 42.96 143.16 42.24 143.28 41.4C143.4 40.56 143.52 39.84 143.52 39C143.52 38.64 143.52 38.28 143.52 37.92C143.52 37.44 143.52 37.08 143.52 36.6V11.4C143.52 10.92 143.52 10.56 143.52 10.08C143.52 9.72 143.52 9.36 143.52 9C143.52 8.16 143.4 7.44 143.28 6.6C143.16 5.76 142.92 5.04 142.56 4.32C142.2 3.6 141.72 3 141.12 2.4C140.52 1.8 139.92 1.32 139.2 0.96C138.48 0.6 137.76 0.36 136.92 0.24C136.08 0.12 135.36 0 134.52 0C134.16 0 133.8 0 133.44 0C133.08 0 132.6 0 132.12 0Z" fill="#BBBBBB"/>
                        <path d="M10.08 46.9205C9.71996 46.9205 9.35996 46.9205 8.99996 46.9205C8.27996 46.9205 7.55996 46.9205 6.71996 46.6805C5.99996 46.5605 5.27996 46.3205 4.67996 46.0805C4.07996 45.7205 3.47996 45.3605 2.99996 44.8805C2.51996 44.4005 2.03996 43.8005 1.79996 43.2005C1.43996 42.6005 1.31996 42.0005 1.19996 41.1605C1.07996 40.3205 0.959961 39.4805 0.959961 38.8805C0.959961 38.6405 0.959961 37.8005 0.959961 37.8005V10.0805C0.959961 10.0805 0.959961 9.24048 0.959961 9.00048C0.959961 8.40048 0.959961 7.56047 1.19996 6.72047C1.43996 6.12047 1.55996 5.40047 1.91996 4.80047C2.27996 4.20047 2.63996 3.60047 3.11996 3.12047C3.59996 2.64047 4.19996 2.28047 4.79996 1.92047C5.39996 1.56047 6.11996 1.44047 6.83996 1.32047C7.55996 1.08047 8.39996 1.08047 8.99996 1.08047H10.08H133.44H134.52C135.12 1.08047 135.96 1.08047 136.8 1.32047C137.52 1.44047 138.12 1.68047 138.84 1.92047C139.44 2.28047 140.04 2.64047 140.52 3.12047C141 3.60047 141.48 4.20047 141.72 4.80047C142.08 5.40047 142.2 6.12047 142.32 6.72047C142.44 7.44047 142.56 8.28048 142.56 9.00048C142.56 9.36048 142.56 9.72048 142.56 10.0805C142.56 10.5605 142.56 10.9205 142.56 11.4005V36.4805C142.56 36.9605 142.56 37.3205 142.56 37.8005C142.56 38.1605 142.56 38.5205 142.56 38.8805C142.56 39.6005 142.44 40.3205 142.32 41.1605C142.2 41.8805 141.96 42.6005 141.72 43.2005C141.36 43.8005 141 44.4005 140.52 44.8805C140.04 45.3605 139.44 45.8405 138.84 46.0805C138.24 46.4405 137.64 46.5605 136.8 46.6805C136.08 46.8005 135.24 46.8005 134.52 46.9205C134.16 46.9205 133.8 46.9205 133.44 46.9205H132.12H10.08Z" fill="black"/>
                        <path d="M29.7596 24.3605C29.7596 21.0005 32.5196 19.4405 32.6396 19.3205C31.0796 17.0405 28.6796 16.8005 27.8396 16.6805C25.7996 16.4405 23.8796 17.8805 22.7996 17.8805C21.7196 17.8805 20.1596 16.6805 18.4796 16.6805C16.3196 16.6805 14.2796 18.0005 13.0796 19.9205C10.7996 23.8805 12.4796 29.8805 14.7596 33.1205C15.8396 34.6805 17.1596 36.4805 18.8396 36.4805C20.5196 36.3605 21.1196 35.4005 23.1596 35.4005C25.1996 35.4005 25.6796 36.4805 27.4796 36.4805C29.2796 36.4805 30.3596 34.9205 31.4396 33.2405C32.7596 31.4405 33.2396 29.6405 33.2396 29.5205C33.2396 29.6405 29.7596 28.3205 29.7596 24.3605Z" fill="white"/>
                        <path d="M26.3995 14.6402C27.2395 13.5602 27.9595 12.0002 27.7195 10.4402C26.3995 10.4402 24.8395 11.2802 23.8795 12.4802C23.0395 13.4402 22.3195 15.0002 22.5595 16.5602C23.9995 16.5602 25.5595 15.7202 26.3995 14.6402Z" fill="white"/>
                        <path d="M50.7604 32.5207H45.1204L43.8004 36.6007H41.4004L46.8004 21.7207H49.2004L54.6004 36.6007H52.2004L50.7604 32.5207ZM45.7204 30.7207H50.2804L48.0004 24.1207H47.8804L45.7204 30.7207Z" fill="white"/>
                        <path d="M66.24 31.2004C66.24 34.5604 64.44 36.7204 61.68 36.7204C60.12 36.7204 58.92 36.0004 58.32 34.8004V40.2004H56.04V25.8004H58.2V27.6004C58.8 26.4004 60.12 25.6804 61.68 25.6804C64.32 25.5604 66.24 27.8404 66.24 31.2004ZM63.84 31.2004C63.84 29.0404 62.76 27.6004 60.96 27.6004C59.28 27.6004 58.08 29.0404 58.08 31.2004C58.08 33.3604 59.28 34.8004 60.96 34.8004C62.76 34.8004 63.84 33.3604 63.84 31.2004Z" fill="white"/>
                        <path d="M78.1199 31.2004C78.1199 34.5604 76.3199 36.7204 73.5599 36.7204C71.9999 36.7204 70.7999 36.0004 70.1999 34.8004V40.2004H67.9199V25.8004H70.0799V27.6004C70.6799 26.4004 71.9999 25.6804 73.5599 25.6804C76.3199 25.5604 78.1199 27.8404 78.1199 31.2004ZM75.8399 31.2004C75.8399 29.0404 74.7599 27.6004 72.9599 27.6004C71.2799 27.6004 70.0799 29.0404 70.0799 31.2004C70.0799 33.3604 71.2799 34.8004 72.9599 34.8004C74.7599 34.8004 75.8399 33.3604 75.8399 31.2004Z" fill="white"/>
                        <path d="M86.04 32.4005C86.16 33.8405 87.6 34.8005 89.64 34.8005C91.56 34.8005 92.88 33.8405 92.88 32.5205C92.88 31.3205 92.04 30.7205 90.12 30.2405L88.2 29.7605C85.44 29.0405 84.24 27.8405 84.24 25.8005C84.24 23.2805 86.52 21.4805 89.64 21.4805C92.76 21.4805 94.92 23.2805 95.04 25.8005H92.76C92.64 24.3605 91.44 23.4005 89.64 23.4005C87.84 23.4005 86.64 24.3605 86.64 25.6805C86.64 26.7605 87.48 27.3605 89.4 27.8405L91.08 28.2005C94.08 28.9205 95.4 30.1205 95.4 32.2805C95.4 35.0405 93.12 36.8405 89.64 36.8405C86.28 36.8405 84.12 35.1605 84 32.4005H86.04Z" fill="white"/>
                        <path d="M99.9602 23.1609V25.681H102V27.481H99.9602V33.481C99.9602 34.441 100.32 34.801 101.28 34.801C101.52 34.801 101.88 34.801 102 34.801V36.601C101.76 36.721 101.28 36.721 100.8 36.721C98.6402 36.721 97.8002 35.881 97.8002 33.841V27.6009H96.2402V25.8009H97.8002V23.281H99.9602V23.1609Z" fill="white"/>
                        <path d="M103.319 31.2005C103.319 27.8405 105.359 25.6805 108.479 25.6805C111.599 25.6805 113.639 27.8405 113.639 31.2005C113.639 34.6805 111.599 36.7205 108.479 36.7205C105.239 36.7205 103.319 34.5605 103.319 31.2005ZM111.359 31.2005C111.359 28.8005 110.279 27.4805 108.479 27.4805C106.679 27.4805 105.599 28.8005 105.599 31.2005C105.599 33.6005 106.679 34.9205 108.479 34.9205C110.279 34.9205 111.359 33.4805 111.359 31.2005Z" fill="white"/>
                        <path d="M115.44 25.6805H117.6V27.6005C117.96 26.4005 118.92 25.6805 120.24 25.6805C120.6 25.6805 120.84 25.6805 120.96 25.8005V27.8405C120.84 27.7205 120.36 27.7205 120 27.7205C118.56 27.7205 117.72 28.6805 117.72 30.2405V36.7205H115.44V25.6805Z" fill="white"/>
                        <path d="M131.28 33.361C131.04 35.281 129 36.721 126.6 36.721C123.48 36.721 121.44 34.561 121.44 31.201C121.44 27.841 123.36 25.561 126.48 25.561C129.48 25.561 131.4 27.601 131.4 30.961V31.681H123.72V31.801C123.72 33.601 124.92 34.921 126.6 34.921C127.8 34.921 128.76 34.321 129.12 33.361H131.28ZM123.72 30.121H129.12C129.12 28.441 128.04 27.361 126.48 27.361C124.92 27.361 123.84 28.561 123.72 30.121Z" fill="white"/>
                        <path d="M45.3597 10.4402C47.5197 10.4402 48.7197 11.7602 48.7197 14.0402C48.7197 16.3202 47.5197 17.6402 45.3597 17.6402H42.7197V10.4402H45.3597ZM43.9197 16.6802H45.2397C46.6797 16.6802 47.6397 15.7202 47.6397 14.1602C47.6397 12.6002 46.7997 11.6402 45.2397 11.6402H43.9197V16.6802Z" fill="white"/>
                        <path d="M50.0391 14.8807C50.0391 13.0807 50.9991 12.1207 52.5591 12.1207C54.1191 12.1207 55.0791 13.2007 55.0791 14.8807C55.0791 16.6807 54.1191 17.6407 52.5591 17.6407C50.9991 17.7607 50.0391 16.6807 50.0391 14.8807ZM53.9991 14.8807C53.9991 13.6807 53.5191 13.0807 52.5591 13.0807C51.5991 13.0807 51.1191 13.8007 51.1191 14.8807C51.1191 16.0807 51.5991 16.8007 52.5591 16.8007C53.5191 16.8007 53.9991 16.0807 53.9991 14.8807Z" fill="white"/>
                        <path d="M61.9191 17.6402H60.8391L59.7591 13.6802H59.6391L58.5591 17.6402H57.4791L56.0391 12.2402H57.1191L58.0791 16.3202H58.1991L59.2791 12.2402H60.3591L61.4391 16.3202H61.5591L62.5191 12.2402H63.5991L61.9191 17.6402Z" fill="white"/>
                        <path d="M64.6797 12.2407H65.7597V13.0807H65.8797C66.1197 12.4807 66.7197 12.1207 67.4397 12.1207C68.6397 12.1207 69.3597 12.8407 69.3597 14.1607V17.6407H68.2797V14.4007C68.2797 13.5607 67.9197 13.0807 67.0797 13.0807C66.2397 13.0807 65.7597 13.5607 65.7597 14.4007V17.5207H64.6797V12.2407Z" fill="white"/>
                        <path d="M70.9199 10.0805H71.9999V17.6405H70.9199V10.0805Z" fill="white"/>
                        <path d="M73.4395 14.8807C73.4395 13.0807 74.3995 12.1207 75.9595 12.1207C77.5195 12.1207 78.4795 13.2007 78.4795 14.8807C78.4795 16.6807 77.5195 17.6407 75.9595 17.6407C74.3995 17.7607 73.4395 16.6807 73.4395 14.8807ZM77.5195 14.8807C77.5195 13.6807 77.0395 13.0807 76.0795 13.0807C75.1195 13.0807 74.6395 13.8007 74.6395 14.8807C74.6395 16.0807 75.1195 16.8007 76.0795 16.8007C76.9195 16.8007 77.5195 16.0807 77.5195 14.8807Z" fill="white"/>
                        <path d="M79.6797 16.0807C79.6797 15.1207 80.3997 14.5207 81.7197 14.5207L83.1597 14.4007V13.9207C83.1597 13.3207 82.7997 13.0807 82.0797 13.0807C81.4797 13.0807 81.1197 13.3207 80.9997 13.6807H79.9197C80.0397 12.7207 80.8797 12.1207 82.0797 12.1207C83.3997 12.1207 84.2397 12.8407 84.2397 13.9207V17.6407H83.1597V16.9207H83.0397C82.6797 17.5207 82.0797 17.7607 81.3597 17.7607C80.3997 17.7607 79.6797 17.0407 79.6797 16.0807ZM83.1597 15.6007V15.1207L81.8397 15.2407C81.1197 15.2407 80.7597 15.6007 80.7597 15.9607C80.7597 16.4407 81.2397 16.6807 81.7197 16.6807C82.5597 16.8007 83.1597 16.3207 83.1597 15.6007Z" fill="white"/>
                        <path d="M85.5596 14.8805C85.5596 13.2005 86.3996 12.1205 87.8396 12.1205C88.5596 12.1205 89.1596 12.4805 89.5196 13.0805H89.6396V10.0805H90.7196V17.6405H89.6396V16.8005H89.5196C89.1596 17.4005 88.5596 17.7605 87.8396 17.7605C86.5196 17.7605 85.5596 16.6805 85.5596 14.8805ZM86.7596 14.8805C86.7596 16.0805 87.3596 16.6805 88.1996 16.6805C89.0396 16.6805 89.6396 15.9605 89.6396 14.8805C89.6396 13.8005 89.0396 13.0805 88.1996 13.0805C87.2396 13.0805 86.7596 13.8005 86.7596 14.8805Z" fill="white"/>
                        <path d="M95.0391 14.8807C95.0391 13.0807 95.9991 12.1207 97.5591 12.1207C99.1191 12.1207 100.079 13.2007 100.079 14.8807C100.079 16.6807 99.1191 17.6407 97.5591 17.6407C95.9991 17.7607 95.0391 16.6807 95.0391 14.8807ZM99.1191 14.8807C99.1191 13.6807 98.6391 13.0807 97.6791 13.0807C96.7191 13.0807 96.2391 13.8007 96.2391 14.8807C96.2391 16.0807 96.7191 16.8007 97.6791 16.8007C98.5191 16.8007 99.1191 16.0807 99.1191 14.8807Z" fill="white"/>
                        <path d="M101.639 12.2407H102.719V13.0807H102.839C103.079 12.4807 103.679 12.1207 104.399 12.1207C105.599 12.1207 106.319 12.8407 106.319 14.1607V17.6407H105.239V14.4007C105.239 13.5607 104.879 13.0807 104.039 13.0807C103.199 13.0807 102.719 13.5607 102.719 14.4007V17.5207H101.639V12.2407Z" fill="white"/>
                        <path d="M112.2 10.9207V12.2407H113.4V13.2007H112.2V15.9607C112.2 16.5607 112.44 16.8007 112.92 16.8007C113.04 16.8007 113.16 16.8007 113.28 16.8007V17.6407C113.16 17.6407 112.92 17.6407 112.68 17.6407C111.48 17.6407 111 17.2807 111 16.2007V13.2007H110.16V12.3607H111V10.9207H112.2Z" fill="white"/>
                        <path d="M114.839 10.0805H115.919V13.0805H116.039C116.279 12.4805 116.879 12.1205 117.719 12.1205C118.919 12.1205 119.639 12.8405 119.639 14.1605V17.6405H118.559V14.4005C118.559 13.5605 118.199 13.0805 117.359 13.0805C116.519 13.0805 116.039 13.6805 116.039 14.4005V17.5205H114.959V10.0805H114.839Z" fill="white"/>
                        <path d="M125.759 16.2007C125.519 17.1607 124.679 17.7607 123.359 17.7607C121.799 17.7607 120.839 16.6807 120.839 15.0007C120.839 13.3207 121.799 12.1207 123.359 12.1207C124.919 12.1207 125.759 13.2007 125.759 14.8807V15.2407H121.919C121.919 16.2007 122.519 16.8007 123.359 16.8007C123.959 16.8007 124.439 16.5607 124.679 16.2007H125.759ZM121.919 14.4007H124.679C124.679 13.5607 124.079 12.9607 123.359 12.9607C122.519 13.0807 122.039 13.5607 121.919 14.4007Z" fill="white"/>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1738_4961">
                    <rect width="144" height="48" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
