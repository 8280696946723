import React from 'react';
import {Navigate, Route, Routes} from 'react-router';
import {Main} from "./pages/main/Main";
import {Rules} from "./pages/rules/Rules";
import {Policy} from "./pages/policy/Policy";
import {QRPage} from "./pages/qr_page/QRPage";
import {Conditions} from "./pages/conditions/Conditions";

const useRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Main/>} />
            <Route path="/policy" element={<Policy/>}/>
            <Route path="/rules" element={<Rules/>}/>
            {/*<Route path="/conditions" element={<Conditions/>}/>*/}
            <Route path="/download" element={<QRPage/>}/>
            <Route path="*" element={<Navigate to="/" />}/>
        </Routes>
    )
}

export default useRoutes;
