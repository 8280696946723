export const Rules = () => {
        const lang = localStorage.getItem("lang");
        if (lang === "kk"){
                return (
                    <div className={"container"} style={{gap: 12}} dangerouslySetInnerHTML={{
                            __html: `<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>Оферта шарты</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:21.0pt;text-indent:-21.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 1.  <b>Жалпы ережелер</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.1. Осы Шарт (Пайдаланушы үшін) барлық мүдделі Пайдаланушыларға
арналған Компанияның ресми жария офертасы (ұсыныс) болып табылады және
Пайдаланушыларға осы Шартта көрсетілген </span><span style='font-size:14.0pt;
line-height:135%;font-family:"Times New Roman",serif'>Қауымдастық</span><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> бойынша келісім жасауды ұсынады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.2. Осы Шарттың мәні - Пайдаланушыларға Компания және
Компаниямен бірлестікке қосылу келісімшартына отырған Серіктестер тарапынан
ұсынылатын марапаттарды пайдалану мүмкіндігін беру. Компания және Серіктестер
Пайдаланушыларға Серіктестердің сауда нүктелерінде Мобильді қосымша арқылы
тауарларды сатып алу үшін марапаттар береді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:21.0pt;text-indent:-21.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 2.  <b>Терминдер мен анықтамалар</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.1. <b>Мобильді қосымшаны активациялау</b> – Пайдаланушының
3.2-тармақта сипатталған дәйекті әрекеттерді орындауы. Мобильді қосымша, егер
Пайдаланушының сауалнамалық деректері (сауалнамалық деректер Мобильді қосымшаны
активациялау үшін міндетті болып белгіленген) Системаға келіп түскен болса және
Мобильді қосымшаны активациялаған Пайдаланушының қолданыстағы ұялы телефон нөмірі
тіркелген болса, активтелген болып есептеледі.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.2. <b>Сауалнама</b> – Бірлестікке қатысу үшін Пайдаланушы
толтыратын форма. Пайдаланушының Компания мен Серіктестерге өз жеке деректерін
ерікті түрде беруі болып табылады. Сауалнаманы Пайдаланушы Мобильді қосымшада
толтырады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.3. <b>Баллдар теңгерімі</b> – Мобильді қосымшаны активациялау
сәтінен бастап ағымдағы уақытқа дейінгі баллдар бойынша транзакциялар
нәтижесінің сомасы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.4. <b>Баллдар</b> – Пайдаланушының Баллдар есебіне
толықтырылатын, Компанияның Пайдаланушының Баллдар есебіне есептейтін,
сондай-ақ Пайдаланушының Баллдар есебінен Серіктестерден тауарларды сатып алу
үшін алынып тасталатын виртуалды шартты бірліктер. Баллдар Пайдаланушыға
Серіктестің Тауарына Баллдарды айырбастау арқылы Тауарды сатып алу құқығын береді.
Бір Балл Серіктестің Тауарын айырбастағанда бір теңгеге тең. Баллдар есептеу
мақсатында пайдаланылады, төлем құралы болып табылмайды және ақша баламасында
берілуі мүмкін емес.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.5. <b>Банк</b> – Пайдаланушыны және/немесе Серіктесті
және/немесе Компанияны қызмет көрсететін екінші деңгейлі банк.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.6. <b>Кері операция</b> – Пайдаланушының Мобильді қосымшаны
пайдалана отырып Серіктестердің Сауда нүктесінде сатып алған Тауарын қайтару
жағдайында Пайдаланушының Баллдар есебіне Баллдардың есепке алынуы операциясы,
егер мұндай сатып алуда Баллдарды есептен шығару операциясы жүргізілсе.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.7. <b>Шарт</b> – Компания мен Пайдаланушы арасындағы осы Шарт,
Мобильді қосымшада жарияланған, шарттары Компаниямен анықталып, Пайдаланушы тек
Шартқа қосылу арқылы ғана қабылдай алады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.8. <b>Байланыс арнасы</b> – Компания және/немесе Серіктес
Пайдаланушыларды Бірлестіктің жұмыс істеуіне тікелей қатысты хабарламаларды,
хабарландыруларды, ақпараттарды, сондай-ақ жарнамалық/ақпараттық сипаттағы
хабарландырулар мен хабарламаларды электрондық пошта арқылы, қысқа мәтіндік
хабарламалар (USSD/SMS), Push-хабарламалар түрінде мобильді құрылғыға
(смартфон, планшет және т.б.) жолдау арқылы хабарлайтын техникалық құралдар
жүйесі.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.9. <b>Компания</b> – ЖШС «Сорпа КЗ», Бірлестік қатысушылары
арасындағы өзара әрекеттестікті қамтамасыз етуді ұйымдастырады, Бірлестік
шеңберінде жасалған шарттардың Ережелерін және шарттарын, сондай-ақ Бірлестікке
қатысу шарттарын айқындайды. Компания Бірлестікті басқару және дамыту құқығына
ерекше құқығы бар.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.10. <b>Компания Дамытушысы</b> – ЖШС «Clover Ltd.», 16.05.2024
ж. №1 кәсіпкерлік лицензияның кешенді келісімшарты негізінде ЖШС «Сорпа КЗ»
мобильді қосымшасын пайдалануға кәсіпкерлік қызметте айрықша құқықтарды беру.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.11. <b>Жеке кабинет</b> – Пайдаланушының ақпараттық кеңістігі,
Мобильді қосымшада Пайдаланушы логин және пароль арқылы қол жеткізетін,
Пайдаланушы туралы ақпарат, Пайдаланушының Баллдар есебі бойынша теңгерім,
Пайдаланушының Мобильді қосымшаны пайдаланып жасаған транзакцияларының тарихы
және Бірлестік шеңберінде орналастырылған басқа да ақпаратты қамтитын.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.12. <b>Мессенджер</b> – мәтіндік хабарламалармен,
аудиожазбалармен, фотосуреттермен және басқа мультимедиа файлдармен жедел
алмасуға арналған бағдарлама немесе қолданба, олар шартты орындау мақсатында
Ақпарат алмасу үшін Тараптар пайдаланылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.13. <b>Мобильді қосымша «Sorpa» (Мобильді қосымша)</b> –
Пайдаланушыларға Серіктестердің іске асыратын Тауарларына қол жеткізу үшін
мобильді құрылғыға (смартфон, планшет және т.б.) орнатылған, қосылған
бағдарламалық жасақтама.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.14. <b>Есеп айырысу көрсеткіші (МРП)</b> – Ережелерде
Пайдаланушының жауапкершілік шарасы ретінде қолданылатын айлық есептік
көрсеткіш; МРП мөлшері Қазақстан Республикасының тиісті кезеңге арналған
республикалық бюджет туралы заңымен белгіленеді;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.15. <b>Баллдарды есептеу</b> – Компанияның бастамасы бойынша
Баллдар есебіне Баллдардың санын ұлғайту операциясы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.16. <b>Бірлестік</b> – Бірлестік шеңберінде жасалған
шарттардың шарттарын орындау мақсатында Компанияны, Пайдаланушыларды және
Серіктестерді қамтитын одақ.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.17. <b>Серіктес</b> – Бірлестік шеңберінде Пайдаланушыға
Мобильді қосымша арқылы Тауарды іске асыратын заңды тұлға/жеке кәсіпкер.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.18. <b>Бірлестіктің Ережелері (Ережелер)</b> – Бірлестікке
қатысу шарттарын, Бірлестік шеңберінде Пайдаланушыларға қызмет көрсету тәртібі
мен шарттарын айқындайтын құжат;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.19. <b>Пайдаланушы</b> – Бірлестікке қосылуға өз еркімен
келісім берген, мобильді құрылғыға Мобильді қосымшаны орнату және оны осы
Шартта көзделген тәртіппен өз еркімен белсендіру арқылы, және/немесе
Серіктестің Тауарын сатып алу үшін Мобильді қосымшаны пайдаланатын, он сегіз
жасқа толған, әрекетке қабілетті шектелмеген жеке тұлға. Пайдаланушы Анкетада,
Өтініштерде және басқа құжаттарда көрсетілген өзінің жеке деректерін Компания
мен Серіктестердің сақтауға және пайдалануға рұқсат береді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.20. <b>Баллдарды толықтыру</b> – Пайдаланушының бастамасы
бойынша Баллдар есебіне Баллдардың санын ұлғайту операциясы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.21. <b>Жүйе</b> – Бірлестік қатысушылары арасындағы ақпараттық
өзара әрекеттестікті қамтамасыз ететін бағдарламалық-аппараттық құралдардың
жиынтығы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.22. <b>Баллдарды есептен шығару</b> – Пайдаланушының Баллдар
есебінен Баллдардың алыну (азаю) операциясы, оның ішінде, Компанияның
бастамасымен Баллдарды есептен шығару, сондай-ақ Серіктестерден Тауарларды
сатып алуды жүзеге асыру арқылы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.23. <b>Тарап немесе Тараптар</b> – Компания және/немесе
Пайдаланушы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.24. <b>Баллдар есебі</b> – Компанияда сақталатын әрбір
Бірлестік Пайдаланушысы бойынша толықтырылған, есептелген және есептен
шығарылған Баллдар саны туралы ақпараттық деректер жиынтығы, сондай-ақ Балдар
есебінің ағымдағы теңгерімі.<br>
<br>
2.25. <b>Тауар</b> – Қазақстан Республикасының заңнамасымен айналымға тыйым
салынбаған және шектелмеген қызметтер/жұмыстар/тауарлар (алкогольдік және
темекі өнімдерінен басқа). Тауарлардың сапасына және оларға Қазақстан
Республикасының заңнамасымен қойылатын барлық басқа талаптарға сәйкестігіне
Серіктестер жауапты.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.26. <b>Сауда нүктелері</b> – Серіктестердің Тауарларды жүзеге
асыратын аумақтық бөлінген бөлімшелері (дүкендер, сауда нысандары, қызмет
көрсету орындары және т.б.).</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.27. <b>Транзакциялар</b> – Мобильді қосымшаны пайдалана
отырып, Пайдаланушының Баллдар есебінде жасаған операциялары (толықтыру,
есептеу және Баллдарды есептен шығару).</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.28. <b>Бірлестік қатысушылары</b> – Компания, Серіктестер және
Пайдаланушылар.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:21.0pt;text-indent:-21.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 3.  <b>Шартты жасасу тәртібі</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>3.1. Шарт Мобильді қосымшада жарияланған және Пайдаланушы
офертаны акцептеген сәттен бастап жасалған болып есептеледі.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>3.2. Офертаны акцептеу (осы Шартты жасасуға ұсынысты қабылдау)
Пайдаланушының келесі әрекеттердің бірін жасауы болып табылады:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>3.2.1. Серіктестен Тауарды сатып алу кезінде Мобильді қосымшаны
алғаш рет пайдалану;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>3.2.2. Мобильді қосымшаны тіркеу арқылы активациялау
(Пайдаланушыға Логинді (ұялы телефон нөмірі немесе электрондық пошта) және
Парольді (Мобильді қосымшаны активациялау кезінде ұялы телефон нөміріне немесе
электрондық поштаға жіберілген пароль) пайдалана отырып, Мобильді қосымшадағы
Жеке кабинетке кіруді қамтамасыз етеді. Тіркеу кезінде Пайдаланушы ұсынылған
Анкетаны толтырады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>3.3. Пайдаланушы акцепт жасай отырып, келесі жағдайларды
растаған болып есептеледі:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Шарттың барлық шарттарымен толық танысып, келіседі;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Мобильді қосымшаны тек жеке, кәсіпкерлік қызметпен
байланысты емес мақсаттарда пайдаланады, және бұл мақсаттар Қазақстан
Республикасының қолданыстағы заңнамасына қайшы келмейді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>3.4. Пайдаланушы 3.2-тармақта көрсетілген әрекеттердің бірін
жасаған сәттен бастап, Пайдаланушы Бірлестік қатысушысы болып саналады.
Көрсетілген әрекеттерді жасау фактісін (Шартты қабылдау) Жүйенің операциялар
журналында тиісті әрекеттердің көрініс табуы растайды.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:21.0pt;text-indent:-21.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 4.  <b>Бірлестікке қосылу тәртібі</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.1. Пайдаланушылардың Бірлестікке қатысуы ерікті негізде жүзеге
асырылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.2. Бірлестіктің қатысушысы - Пайдаланушысы, тек 18 (он сегіз)
жасқа толған, Бірлестікке тіркелу кезінде кәсіпкерлік қызметпен айналыспайтын
жеке тұлға болуы мүмкін.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.3. Кез келген әрекетке қабілетті жеке тұлға, осы Шартқа
офертаны акцептеу және Мобильді қосымшаны активациялау арқылы Бірлестік
қатысушысы болып табылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.4. Шарт Пайдаланушының Мобильді қосымшада жарияланған офертаны
акцептеу арқылы осы Шартқа қосылу арқылы жасалады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.5. Бір ұялы телефон нөміріне тек бір Мобильді қосымша белсендірілген
болуы мүмкін. Пайдаланушы Мобильді қосымшаны белсендірген ұялы телефон нөмірін
жеке кабинет арқылы өзгерте алады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.6. Пайдаланушы 3.2-тармақта көрсетілген әрекеттерді жасау,
Бірлестікке қосылу, сондай-ақ Бірлестік аясында Баллдарды есептеу және есептен
шығару бойынша әрекеттерді жасау арқылы, ол Шарттың барлық шарттарымен
танысқанын және келісетінін растайды, осылайша, ол осы Шарт бойынша
міндеттемелерді қабылдайды және оны толығымен, шегерімдер мен ескертулерсіз
орындайды. Көрсетілген әрекеттерді жасау фактісі Жүйенің операциялар журналында
көрініс табуы арқылы расталады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.7. Компания техникалық немесе басқа мүмкіндіктер болмаған
жағдайда, сондай-ақ Мобильді қосымшаны белсендіру сұранысының дұрыс болмауы
немесе Мобильді қосымшаны белсендіру үшін қажетті барлық талаптардың сақталмауы
жағдайында, әлеуетті Пайдаланушыны Бірлестікке қатыстырудан бас тартуға құқылы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.8. Бірлестік аясында Пайдаланушы Серіктестен Тауарды сатып алу
үшін Баллдарды пайдаланады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.9. Пайдаланушы Мобильді қосымшаны белсендірген жағдайда ғана,
осы Шартта көзделген шарттарды сақтай отырып, Баллдарды пайдалануға құқылы.
Мобильді қосымшаны бұғаттаған жағдайда, Пайдаланушы Мобильді қосымшаны
пайдалану арқылы Баллдарды пайдалану құқығынан айырылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.10. Пайдаланушы тіркеу кезінде Жеке кабинетке кіру үшін
пайдаланған деректерді, оның ішінде Логин мен Парольді құпия сақтауға міндетті.
Компания мен Серіктестер Пайдаланушының Логині мен Паролі пайдаланушы тарапынан
жарияланған жағдайларда, үшінші тұлғалардың Жеке кабинетке рұқсатсыз кіруі
салдарынан туындаған залалдар үшін жауапкершілік көтермейді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.11. Пайдаланушы Бірлестікке қосылу кезінде көрсетілген
деректердің дұрыстығы мен өзектілігіне, сондай-ақ оларды уақтылы жаңартуға
жауапты.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.12. Бірлестікке тіркелу кезінде Пайдаланушы пайдаланған ұялы
телефон нөмірі автоматты түрде Пайдаланушының Баллдар есебін басқаруға
авторизацияланады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.13. Егер Пайдаланушының Баллдар есебі бойынша толықтыру
және/немесе есептеу және/немесе есептен шығару операциялары 12 (он екі) күнтізбелік
ай ішінде жүргізілмесе, сондай-ақ Пайдаланушы өз жеке деректерін өңдеуге берген
келісімін қайтарып алса, Пайдаланушы автоматты түрде Бірлестіктен шығарылады,
ал Мобильді қосымша бұғатталады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.14. Пайдаланушының Бірлестікке қатысуы қандай да бір себеппен
тоқтатылған жағдайда, Пайдаланушының Баллдар есебіндегі барлық Баллдар ешқандай
қабылдаусыз есептен шығарылады, Мобильді қосымша бұғатталады, Пайдаланушы
туралы ақпарат Жүйеден жойылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>4.15. Пайдаланушының Баллдар есебіне қателікпен есептелген
Баллдар, оның ішінде жабдықтың, бағдарламалық жасақтаманың ақаулары салдарынан
қателікпен есептелген Баллдар Компаниямен ешқандай хабарлаусыз есептен
шығарылады. Осы Шартта көзделген негіздер бойынша есептен шығарылған Баллдар
қалпына келтірілмейді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:21.0pt;text-indent:-21.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 5.  <b>Мобильді қосымша және оның қызметі</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>5.1. Мобильді қосымша Компанияның меншігі болып табылады.
Мобильді қосымшада орналастырылған ақпарат Пайдаланушы үшін Серіктестерден
ерекше қызмет көрсету шарттарын алу үшін пайдаланылуы керек және оны басқа
тұлғаға беру мүмкін емес. Мобильді қосымшаны орнатқан мобильді құрылғыны
Пайдаланушы басқа тұлғаға берген жағдайда туындаған барлық тәуекелдерді
Пайдаланушы өзі көтереді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>5.2. Мобильді құрылғы жоғалған, ұрланған немесе зақымдалған
жағдайда, Пайдаланушы бұл туралы Компанияны Байланыс арнасы арқылы хабарлауы
керек. Хабарлама алғаннан кейін, Компания мұндай Мобильді қосымшаны бұғаттайды,
ал Пайдаланушы басқа мобильді құрылғы арқылы Компаниядан Мобильді қосымшаға қол
жеткізу құқығын, барлық ақпарат пен Баллдарды сақтай отырып, тегін алуға
құқылы, егер ол қосымшаны қолдану құқығын растайтын болса. Бұл жағдайда
Компания үшінші тұлғалардың Мобильді қосымшаны рұқсатсыз пайдалануына жауапты
болмайды.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>5.3. Серіктестен Мобильді
қосымшаны пайдалана отырып, Тауарларды сатып алу Жүйеде жазылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>5.4. Мобильді қосымшаны пайдалана отырып алынған тауарлар
Қазақстан Республикасының қолданыстағы заңнамасына сәйкес Серіктестің сауда
нүктелерінде қайтаруға немесе айырбастауға жатады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>5.5. Компания келесі жағдайларда Пайдаланушының Мобильді
қосымшасын бұғаттау құқығын өзіне қалдырады:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>5.5.1. Пайдаланушының Шартты, Ережелерді және Бірлестікке қатысу
шарттарын реттейтін басқа да құжаттарды бұзуы;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>5.5.2. Басқа Пайдаланушылардан, Серіктестерден және/немесе
үшінші тұлғалардан Пайдаланушының Шартты, Ережелерді және Бірлестікке қатысу
шарттарын реттейтін басқа да құжаттарды бұзғаны туралы шағымдар Компанияға
түскен жағдайда;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>5.5.3. Пайдаланушының Қазақстан Республикасының қолданыстағы
заңнамасына қайшы келетін әрекеттерінің анықталуы;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>5.5.4. Компания тарапынан Пайдаланушыны сәйкестендіру
қажеттілігі туындаған кезде, Пайдаланушының өзінің Бірлестікке қатысушысы
екенін растайтын құжаттарды беруден бас тартуы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>5.6. Пайдаланушы Бірлестік туралы ақпаратты келесі жолдармен ала
алады:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Мобильді қосымша арқылы;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Байланыс арнасы арқылы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:21.0pt;text-indent:-21.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 6.  <b>Жүйе</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>6.1. Жүйеде Пайдаланушы енгізген, Компания мен Серіктестер
өңдейтін және Бірлестік қатысушылары арасындағы ақпараттық өзара әрекеттестікті
қамтамасыз ету үшін арналған ақпарат айналымға түседі.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>6.2. Жүйеде ақпаратқа қол жеткізу Пайдаланушы мен
Компания/Серіктестер арасындағы өзара әрекеттестік деңгейінде шектеледі. Әрбір
Пайдаланушы тек осы Пайдаланушының Бірлестіктегі қызметімен тікелей байланысты
ақпаратқа және осы Пайдаланушы Жүйеге енгізген ақпаратқа қол жеткізе алады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>6.3. Жүйеге ақпарат енгізу кезінде Пайдаланушы толық заңды
жауапкершілікпен барлық қажетті келісімдер мен рұқсаттарды алдым деп кепілдік
береді, яғни Пайдаланушы осы ақпараттың құқық иесі, иесі немесе көзі болып
табылады немесе заңды негізде осы ақпаратты басқа иелер (иелері, көздері)
пайдалануға берген.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>6.4. Жүйеге ақпарат енгізген Пайдаланушы барлық қажетті
келісімдер мен рұқсаттарды өз бетінше және жауапкершілікпен алады, сондай-ақ
енгізілген ақпараттың дұрыстығы мен шынайылығына, оның дер кезінде енгізілуіне
жауап береді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>6.5. Пайдаланушы өз ақпаратын Байланыс арнасы және/немесе
интернет желісі арқылы электронды нысанда жіберуге келісім береді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>6.6. Компания мен Серіктестер ақпаратты тек Шарт пен Ережелерде
көзделген мақсаттар мен шарттарға сәйкес пайдалануға құқылы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>6.7. Жүйеге Пайдаланушы енгізген және нақты физикалық тұлғаға
немесе нақтылауға байланысты ақпарат физикалық тұлғаға қатысты болса, ол жеке
деректерге жатады және Қазақстан Республикасының заңнамасына сәйкес өңделеді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>6.8. Компания мен Серіктестер Жүйені пайдаланған кезде белгілі
болған ақпараттың құпиялылығын қамтамасыз етуге міндеттенеді және осы ақпаратты
сақтау немесе пайдалану үшін ең аз қажетті қол жеткізуге ғана рұқсат береді,
бұл жұмысшыларға олардың құпиялылықты сақтау міндеттемелері мен оны жария ету
үшін Қазақстан Республикасының заңнамасында белгіленген жауапкершілік туралы
алдын ала түсіндіріп беріледі.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:21.0pt;text-indent:-21.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 7.  <b>Бірлестік қатысушыларының құқықтары мен міндеттері</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.1. <b>Компанияның Бірлестікті ұйымдастыру және басқару
жөніндегі өкілеттіктері:</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.1.1. Пайдаланушыларды тартуды ұйымдастыру;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.1.2. Бірлестік қатысушылары арасындағы ақпараттық-техникалық
өзара әрекеттестіктің тәртібін айқындау;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.1.3. Пайдаланушының сұранысы бойынша Мобильді қосымшаны
белсендіру, бұғаттау және бұғаттан шығару жұмыстарын орындау. Пайдаланушының
сұранысы бойынша, осы Пайдаланушының Мобильді қосымшасын пайдалану арқылы
жасалған Транзакциялар туралы ақпарат беру;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.1.4. Пайдаланушыларды Серіктестердің ұсыныстары, Тауарлары,
өткізілетін іс-шаралары туралы хабардар ету үшін Байланыс арнасы арқылы
ақпараттандыру;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.1.5. Бірлестік шеңберіндегі барлық ұйымдастыру мәселелері
бойынша қатысушыларға қолдау көрсету;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.1.6. Пайдаланушы туралы және төлем сомалары туралы ақпаратты
жария етпеу, егер Қазақстан Республикасының қолданыстағы заңнамасына сәйкес
мұндай ақпаратты уәкілетті мемлекеттік органға беру міндеттемесі көзделмеген
болса, сондай-ақ мұндай ақпарат жалпыға ортақ қолжетімді немесе Пайдаланушының
рұқсатымен ашылған болса;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.1.7. Мобильді қосымшаны, егер Шарт талаптары сақталған болса,
белсендіру.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.2. <b>Компания мен Серіктестердің Бірлестік аясындағы
құқықтары:</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.2.1. Бірлестіктің жұмысына тікелей байланысты ақпаратты
Пайдаланушыларға беру. Мұндай материалдарды жариялау және орналастыру үшін
Компания мен Серіктестер өз бастамасы бойынша, сондай-ақ Қазақстан
Республикасының қолданыстағы заңнамасының талаптарына сәйкес Пайдаланушыларға
берілетін құжаттарда және Жүйе арқылы қалыптасатын басқа құжаттарда қосымша
ақпаратты, оның ішінде жарнамалық сипаттағы ақпаратты орналастыруға құқылы;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.2.2. Бірлестіктің қызметін жүзеге асыру үшін Компанияның
үшінші тұлғаларды тарту құқығы бар;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.2.3. Компания Бірлестіктің қызметін кез келген уақытта тоқтата
алады немесе тоқтата алады, бұл туралы Пайдаланушыларға тоқтатылғанға дейін 20
(жиырма) күнтізбелік күн бұрын Мобильді қосымша немесе Байланыс арнасы арқылы
хабарландыру жіберу арқылы хабарлауға міндетті. Бірлестіктің қызметі
тоқтатылған мерзім ішінде, сондай-ақ Бірлестік қызметі тоқтатылған күннен
бастап (егер Бірлестік қызметі тоқтатылса) Компания Баллдарды толықтыруды,
есептеуді және есептен шығаруды жүзеге асырмайды, сондай-ақ Бірлестік қызметі
бойынша өз міндеттемелерін орындауды тоқтатады. Бірлестік қызметінің
тоқтатылғаны туралы хабарлама Мобильді қосымшада немесе Байланыс арнасы арқылы
орналастырылғаннан кейін 20 (жиырма) күнтізбелік күн ішінде Пайдаланушылар
Баллдарын пайдаланбаған жағдайда, Пайдаланушылардың барлық Баллдары толық
көлемде жойылады, бұл ретте Компания мен Серіктестер Пайдаланушыларға ешқандай
төлемдер мен өтемақылар жасамайды.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.3. <b>Компания мен Серіктестердің міндеттері:</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>Компания мен Серіктестер Пайдаланушыларды Шарт және Ережелерде
көзделген шарттарда қызмет көрсетуді қамтамасыз етуге міндеттенеді.
Пайдаланушыларға қызмет көрсету жүргізілетін Серіктестердің сауда нүктелерінің
тізімін Компания Мобильді қосымшада орналастырады. Компания мен Серіктестер
Бірлестік шеңберінде Пайдаланушыларға Тауарларды беру кезінде Қазақстан
Республикасының қолданыстағы заңнамасымен көзделген барлық Пайдаланушылардың
құқықтарын қамтамасыз етуге міндеттенеді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.4. <b>Пайдаланушының міндеттері:</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.4.1. Осы Шарт пен Ережелердің талаптарын орындау;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.4.2. Мобильді қосымшаны орнатқан мобильді құрылғының
қауіпсіздігіне тиісті түрде қамқорлық жасау. Үшінші тұлғаларға орнатылған
Мобильді қосымшасы бар мобильді құрылғыны беру кезінде, осы үшінші тұлғалардың
Мобильді қосымшаны пайдалануы үшін жауапкершілікті бірлесіп көтереді. Мобильді
қосымшаны үшінші тұлғалардың құқықтарын бұзатын заңсыз мақсаттарда, сондай-ақ
кәсіпкерлік қызметті жүзеге асыру және/немесе Қазақстан Республикасының
заңнамасымен тыйым салынған тауарларды сатып алу мақсатында пайдалану;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>7.4.3. Серіктестен
Тауарларды сатып алу шарттарын дербес зерттеп білу;<br>
7.4.4. Пайдаланушы осы Шартқа, Ережелерге, сондай-ақ Компанияның Мобильді
қосымшаны пайдалану шарттарына енгізілген өзгерістер мен толықтыруларды өз
бетінше қадағалап отыруға міндетті. Мобильді қосымшаны пайдалана отырып,
Пайдаланушының транзакциялар жасауы енгізілген өзгерістермен (жаңа
редакциясымен) келісімнің растығын білдіреді;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span style='font-size:14.0pt;line-height:135%;font-family:"Times New Roman",serif'>       <br>
<span style='color:#0E0E0E'>7.4.5. Бірлестікке қатысушы ретіндегі міндеттерін
орындау.</span></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.5. <b>Бірлестік аясында Пайдаланушының құқықтары:</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.5.1. Пайдаланушыларға арналған қосымша сервистер мен
қызметтерді пайдалану;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.5.2. Транзакциялар және Мобильді қосымшаны пайдалану туралы
ақпарат алу;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.5.3. Өз қалауы бойынша және Мобильді қосымша арқылы ұсынылатын
мүмкіндіктер аясында толтырылған және есептелген Баллдарды пайдалану.
Пайдаланушыға қолжетімді тауарлардың толық тізімі туралы ақпаратты Мобильді
қосымша арқылы, сондай-ақ оларды ұсынатын Серіктестерден алуға болады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.5.4. Пайдаланушы Шартқа және Ережелерге сәйкес келмеген
жағдайда, немесе енгізілген өзгерістермен келіспеген жағдайда, Бірлестіктен өз
қатысуын біржақты тәртіппен тоқтатуға құқылы. Пайдаланушы Бірлестіктен өз
еркімен шыққан жағдайда, Пайдаланушының Баллдар есебіндегі барлық Баллдар
ешқандай қабылдаусыз есептен шығарылады, Мобильді қосымша бұғатталады,
Пайдаланушы туралы ақпарат Жүйеден жойылады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.5.5. Пайдаланушының Бірлестіктен өз еркімен шығуы мынадай
фактілермен расталады:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.5.5.1. Компанияға Пайдаланушының аты-жөні және телефон нөмірі
көрсетілген еркін нысанда жазылған Пайдаланушының сәйкес өтінішін тапсыруы
(немесе пошта арқылы, курьер арқылы, тапсырыс хатпен жіберуі);</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.5.5.2. Пайдаланушының Мобильді қосымшаны 12 (он екі) ай бойы
пайдаланбауы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.6. <b>Жеке деректерді өңдеу:</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.6.1. Бірлестікке қосылу арқылы Пайдаланушы Компанияға және
Серіктестерге келесі жеке деректерін өз еркімен және өз бастамасымен ұсынады:
тегі, аты, әкесінің аты, жынысы, туған күні, ұялы телефон нөмірі, электрондық
пошта, тұрғылықты қаласы және көшесі және Мобильді қосымшаны белсендіру кезінде
көрсетілген басқа да деректер. Компания мен Серіктестер көрсетілген жеке
деректерді автоматтандырылған және автоматтандырылмаған түрде өңдеуге құқылы,
оның ішінде оларды жинау, жазу, жүйелеу, жинақтау, сақтау, нақтылау (жаңарту,
өзгерту), шығару, пайдалану, беру (тарату, ұсыну, қол жеткізу), жасырын ету,
бұғаттау, жою, жою. Пайдаланушы жеке деректерін өңдеуге келесі мақсаттарда
келісім береді:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Бірлестіктің қалыпты жұмысын қамтамасыз ету;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Компания мен Серіктестердің Шарт пен Ережелерден туындайтын
міндеттемелерін орындау;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Бірлестік қызметін бағалау және талдау;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Пайдаланушының Бірлестіктің қызметіне қатысты хабарламалар
мен ақпаратты алу;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Пайдаланушының Компания мен Серіктестерден
жарнамалық/ақпараттық хабарландыруларды алу.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.7. <b>Жеке деректерді үшінші тұлғаларға беру:</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.7.1. Пайдаланушы Компания мен Серіктестерге өз жеке деректерін
үшінші тұлғаларға беру құқығын береді, келесі мақсаттарда:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Бірлестіктің жұмысын қамтамасыз ету;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Пайдаланушыны Бірлестіктің арнайы ұсыныстары туралы қосымша
хабардар ету;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Компания мен Серіктестердің Шарттан туындайтын
міндеттемелерін орындау;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Компания, Серіктестер мен үшінші тұлғалар арасындағы
келісімшарттық міндеттемелерді орындау.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.8. <b>Жеке деректерді өңдеуге келісімнің ұзақтығы:</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.8.1. Пайдаланушының Компания мен Серіктестерге Бірлестікке
қосылу кезінде берген жеке деректерді өңдеуге келісімі Бірлестік қызметінің
бүкіл мерзіміне қолданылады, егер Қазақстан Республикасының қолданыстағы
заңнамасымен өзгеше көзделмесе және Пайдаланушы кез келген уақытта Компанияға
жазбаша хабарлама жіберу арқылы келісімін қайтарып алуға құқылы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.9. <b>Жарнамалық/ақпараттық хабарламаларды алу:</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>7.9.1. Бірлестікке қосылу арқылы Пайдаланушы Компания мен
Серіктестердің Байланыс арнасы арқылы Бірлестіктің жұмыс істеуіне тікелей
қатысты хабарламаларды, хабарландыруларды, сондай-ақ жарнамалық/ақпараттық
сипаттағы хабарландырулар мен хабарламаларды алуға өз келісімін береді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:21.0pt;text-indent:-21.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 8.  <b>Пайдаланушылардың Баллдарын толықтыру және есептеу
шарттары:</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>8.1. Пайдаланушының Баллдар есебін толықтыру Мобильді қосымша
арқылы төлем жүйелері мен Банкті пайдалана отырып 1:1 (бір балл бір теңгеге)
арақатынасында жүзеге асырылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>8.2. Ақшалай төлем келесі тәсілдердің бірімен жүзеге асырылуы
мүмкін:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Клиенттің банктік картасы арқылы Төлем жүйесі және Сервис
арқылы төлем;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Клиенттің банктік картасы арқылы мобильді төлем жүйелерін
пайдалана отырып (Apple Pay, Google Pay және/немесе Samsung Pay) төлем.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>8.3. Егер Клиенттің банктік картасы 3D Secure технологиясын
қолдаса, аударымды жүзеге асыру үшін банк-эмитентте қосымша тексеруден өту
қажет болады. Клиент банк берген бетке бағытталады. Тексеру тәртібі картаны
шығарған банкпен анықталады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>8.4. Пайдаланушының Баллдар есебін толықтырған кезде Компания
Пайдаланушыға келесі мөлшерде Баллдарды есептейді:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>8.4.1. Пайдаланушының Баллдар есебін 1 000,00 теңгеден 9 999,00
теңгеге дейін толықтырған кезде Компания толықтыру сомасының 5% қосымша
Баллдарды есептейді;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>8.4.2. Пайдаланушының Баллдар есебін 10 000,00 теңгеден 49
999,00 теңгеге дейін толықтырған кезде Компания толықтыру сомасының 10% қосымша
Баллдарды есептейді;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>8.4.3. Пайдаланушының Баллдар есебін 50 000,00 теңгеден 99
999,00 теңгеге дейін толықтырған кезде Компания толықтыру сомасының 15% қосымша
Баллдарды есептейді;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>8.4.4. Пайдаланушының Баллдар есебін 100 000,00 теңгеден көп
толықтырған кезде Компания толықтыру сомасының 20% қосымша Баллдарды есептейді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>Пайдаланушының Баллдар есебін толықтырғаннан және Компанияның
Баллдарды есептегеннен кейін Пайдаланушы Баллдарды Баллдар есебінен Банкке
аударуға және/немесе басқа Пайдаланушының Баллдар есебіне аударуға құқығы жоқ.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>8.5. Барлық Баллдарды толықтыру және есептеу операциялары Жүйеде
жүргізіледі және Серіктестердің Мобильді қосымшасына беріледі. Жүйе мен
Серіктестердің Мобильді қосымшасы арасындағы өзара әрекеттестік (ақпарат
алмасу) on-line режимінде жүзеге асырылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>8.6. Пайдаланушының Баллдар есебін толықтыру және Компанияның
Баллдарды есептеу, Баллдар есебін толықтыру сәтінен бастап 24 сағаттан
кешіктірмей жүзеге асырылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:21.0pt;text-indent:-21.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 9.  <b>Баллдарды есептен шығару шарттары:</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>9.1. Баллдар есептеу мақсатында пайдаланылады және төлем құралы
болып табылмайды, сондықтан ақшалай баламада берілуі мүмкін емес.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>9.2. Серіктестен Тауарды сатып алу кезінде Баллдарды есептен
шығару 1:1 (бір балл бір теңгеге) арақатынасында жүзеге асырылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>9.3. Серіктестен Тауарды
сатып алу үшін Баллдарды есептен шығару кезінде алдымен Пайдаланушының
есебіндегі қосымша есептелген Баллдар есептен шығарылады. Негізгі Баллдар
қосымша есептелген Баллдар есептен шығарылғаннан кейін ғана есептен шығарылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>9.4. Компания Пайдаланушының Шарт пен Ережелердің талаптарын
бұзған жағдайда, сондай-ақ Мобильді қосымша арқылы жасалған транзакциялар
кезінде алаяқтық әрекеттер анықталған жағдайда, Пайдаланушының Баллдар есебінен
Баллдарды есептен шығару операцияларын кез келген уақытта бұғаттау құқығын өзіне
қалдырады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>9.5. Компания Пайдаланушының Баллдар есебінен Баллдарды ешқандай
қабылдаусыз келесі жағдайларда есептен шығару құқығын өзіне қалдырады:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>9.5.1. Компанияның қателікпен Баллдарды толықтыруы және/немесе
есептеу жағдайында, оның ішінде бағдарламалық жасақтаманың және байланыс
арналарының жұмысындағы техникалық ақауларға байланысты, сондай-ақ басқа да
техникалық ақаулар салдарынан, қателікпен толықтырылған және/немесе есептелген
Баллдар Пайдаланушының Баллдар есебінен ешқандай қабылдаусыз есептен шығарылады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>9.5.2. Пайдаланушының және/немесе үшінші тұлғалардың алаяқтық
әрекеттері және/немесе адал емес әрекеттері және/немесе әрекетсіздігі
жағдайында, Пайдаланушының Баллдар есебінде қалған барлық Баллдар ешқандай
қабылдаусыз есептен шығарылады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>9.5.3. Пайдаланушының Баллдар есебіндегі соңғы транзакциядан
бастап 30 (отыз) күнтізбелік күн өткен жағдайда, қосымша есептелген Баллдар
ешқандай қабылдаусыз есептен шығарылады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>9.5.4. Компанияның бастамасы бойынша Бірлестіктің қызметі
тоқтатылған жағдайда, оның ішінде үшінші тараптың, сондай-ақ Қазақстан
Республикасының қолданыстағы заңнамасындағы өзгерістерге байланысты, барлық
Пайдаланушылардың Баллдар есебінен Баллдар ешқандай қабылдаусыз есептен
шығарылады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>9.5.5. Пайдаланушының Бірлестіктен шығуы кез келген негіз
бойынша тоқтатылған жағдайда, осы Пайдаланушының Баллдар есебіндегі барлық
Баллдар ешқандай қабылдаусыз есептен шығарылады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>9.5.6. Пайдаланушының Баллдар есебінен ешқандай қабылдаусыз
есептен шығарылатын Баллдардың саны Пайдаланушының Баллдар есебіндегі қалған
Баллдардан аспауы керек.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:30.0pt;text-indent:-30.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 10.  <b>Қайтару операцияларын жүргізу</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>10.1. Пайдаланушы сатып алған Тауарды қайтару кезінде, Компания
Серіктестің нұсқауы бойынша Пайдаланушының Баллдар есебіне осы Тауар үшін
қабылданған Баллдарды қайтаруды жүзеге асырады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>10.2. Серіктестің Пайдаланушыға қайтарылатын Тауарды басқа
Тауарға ауыстыру мүмкіндігі болған жағдайда, Пайдаланушының қалауы бойынша
мұндай айырбастау/ауыстыру жүргізілуі мүмкін. Қалай болғанда да, Тауарды
айырбастау/ауыстыру мүмкіндігі және тәртібі Қазақстан Республикасының
қолданыстағы заңнамасына сәйкес айқындалады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>10.3. Тауарды қайтаруға негізделген Пайдаланушының Баллдар
есебіндегі Баллдарды қайтару операциясын жүргізуге, сондай-ақ Пайдаланушының
Баллдар есебіне Баллдарды есепке алу жауапкершілігі Компанияға жүктеледі.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:30.0pt;text-indent:-30.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 11.  <b>Пайдаланушылардың шағымдарын реттеу тәртібі</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>11.1. Егер Пайдаланушы өз Баллдар есебін толықтыру және/немесе
Баллдарды есепке алу, сондай-ақ Мобильді қосымшаны пайдалану бойынша кез келген
басқа шағымдар бойынша Компанияға шағыммен жүгінсе, Компания мұндай шағымдардың
себептерін анықтау үшін барлық күш-жігерін жұмсауға міндеттенеді. Егер
шағымдардың себептері Компанияның кінәсінен туындаған Жүйедегі қателіктер
немесе ақаулар болса, Компания бұл себептерді өз күшімен және өз есебінен жоюға
және Пайдаланушының шағымын реттеуге міндеттенеді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>11.2. Егер шағымдардың себептері Серіктестер тарапынан туындаған
қателіктер, әрекеттер және/немесе әрекетсіздік немесе Серіктестердің
ақауларынан туындаған техникалық ақаулар болса, Компания бұл шағымды
Серіктестің мекенжайына болжалды қателіктер себептерін көрсетіп жібереді.
Серіктеспен келісе отырып, Пайдаланушының шағымын реттеу үшін қажетті шараларды
Компания немесе Серіктес өз күшімен, сондай-ақ осы қателіктерді жоюға құзыретті
үшінші тұлғалардың күшімен жүзеге асырады. Пайдаланушының шағымын реттеу үшін
қажетті шығындар мен шараларды төлеу Серіктес пен Компанияның кінәсі теңдей
болған жағдайда, жауапкершілік теңдей бөлінеді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>11.3. Пайдаланушының Баллдар есебін толықтыру және/немесе есепке
алу және/немесе есептен шығару бойынша кез келген шағымдарға қатысты Шарт
талаптарын бұзған жағдайда, Компанияның Пайдаланушы алдында жауапкершілігі тек
қажетті Баллдардың Пайдаланушының Баллдар есебіне есепке алынуымен шектеледі.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>11.4. Пайдаланушының сатып алынған Тауар сапасына қатысты шағымы
болған жағдайда, Компания бұл шағымды «Пайдаланушының Тауарға қатысты
шағымдарын қарау ережелеріне» сәйкес қарайды.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:30.0pt;text-indent:-30.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 12.  <b>Форс-мажорлық жағдайлар</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>12.1. Компания мен Серіктестер форс-мажорлық жағдайлардың салдарынан
Шарт пен Ережелер талаптарын ішінара немесе толық орындамағаны үшін
жауапкершіліктен босатылады, бұл форс-мажорлық жағдайлар Компания мен
Серіктестердің міндеттемелерін орындауға әсер ететін төтенше оқиғалардан
туындаған және оларды алдын алу немесе алдын алу мүмкін болмады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>12.2. Форс-мажорлық жағдайларға Компания мен Серіктестердің
ықпал ете алмайтын және олардың туындауына жауапты емес оқиғалар жатады,
мысалы, жер сілкінісі, су тасқыны, дауыл және басқа да табиғи апаттар,
сондай-ақ соғыс, әскери әрекеттер, лаңкестік актілер, өрттер, апаттар,
сондай-ақ мемлекеттік билік және басқару органдарының қаулылары немесе
бұйрықтары.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>12.3. Форс-мажорлық жағдайлар туындаған жағдайда,
міндеттемелерді орындау мерзімі мұндай жағдайлардың және олардың салдарының
әрекет ету мерзіміне шегеріледі. Форс-мажорлық жағдайлардың басталуы мен
аяқталу сәті туралы даулар туындаған жағдайда, ол Қазақстан Республикасының
тиісті құзыретті мемлекеттік органдары берген осы жағдайлардың мерзімдерін
құжаттық растай отырып шешіледі.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:30.0pt;text-indent:-30.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 13.  <b>Шарт пен Ережелерге өзгерістер мен толықтырулар енгізу
тәртібі</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>13.1. Компания осы Шарт пен Ережелерге біржақты тәртіппен
өзгерістер енгізу құқығын өзіне қалдырады. Шарт пен Ережелердің жаңа редакциясы
өзгерістер енгізілгеннен кейін Компаниямен Мобильді қосымшада кемінде 30 (отыз)
күн бұрын жарияланады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>13.2. Егер Пайдаланушы жаңа редакциядағы Шарт пен/немесе
Ережелерге сәйкес Бірлестікке қатысқысы келмесе, Пайдаланушы өзінің қатысуын
осы Шартпен белгіленген тәртіппен тоқтатады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>13.3. Шарт пен/немесе Ережелердің жаңа редакциясы күшіне енген
сәттен бастап, барлық Пайдаланушылар Шарт пен Ережелердің жаңа редакциясын
басшылыққа алады. Бұрын тіркелген барлық Пайдаланушылар Бірлестіктегі өз
қатысуын жалғастыра отырып, Шарт пен/немесе Ережелердің жаңа редакциясымен
сөзсіз келісетіндіктерін білдіреді және өздерінің жеке деректерін Компания мен
Серіктестерге өңдеуге келісім береді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>13.4. Әрбір Пайдаланушы Шартты Компаниямен біржақты өзгерту
құқығын толық түсінеді және мойындайды, Бірлестікте өзгертілген шарттарда
қатысуды қабылдайды.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>13.5. Қатысушылар Шарт пен
Ережелерге өзгерістер енгізуді өз бетінше қадағалауға міндетті.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>13.6. Компания Шартты, Ережелерді және басқа құжаттарды
(мұндайлар болған жағдайда), сондай-ақ олардың барлық өзгерістерін 3 (үш) жыл
бойы сақтау міндетін атқарады, бұл өзгерістер енгізілген сәттен бастап
есептеледі.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:30.0pt;text-indent:-30.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 14.  <b>Қорытынды ережелер</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.1. Шарт Тараптармен мерзімсіз (белгіленбеген уақытқа)
жасалады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.2. Шарттың шарттарын Компания анықтайды және Пайдаланушы тек
Шартқа қосылу арқылы қабылдай алады, бұл Қазақстан Республикасының Азаматтық
кодексінің 389-бабына сәйкес жүзеге асырылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.3. Шарт пен/немесе Ережелерді бұзғаны үшін, Компания
Пайдаланушының Баллдар есебінен 1000 АЕК дейінгі мөлшерде айыппұл (өсімпұл)
есептеп шығару құқығын өзіне қалдырады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.4. Шарт пен/немесе Ережелерді бұзғаны үшін, Компания
Пайдаланушыға айыппұл (өсімпұл) төлеумен қатар, келесі шараларды қолдануға
құқылы:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Мобильді қосымшаны бұғаттау;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Компания есептеген Баллдарды жою;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Бірлестіктегі қатысуды тоқтату.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.5. Компания Пайдаланушының есептік және басқа ақпараттың
үшінші тұлғаларға қолжетімділігі нәтижесінде болған кез келген залалдар үшін
жауапкершілік көтермейді, бұл ақпараттың жария етілуі Компанияның кінәсінен
емес.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.6. Пайдаланушы Мобильді қосымшаны өзінің Жеке кабинетінде
және/немесе Жеке кабинетте өзінің атынан пайдаланған барлық әрекеттер үшін
жауапкершілік көтереді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.7. Серіктестің/Пайдаланушының жалған, дұрыс емес немесе
заңсыз ақпаратты ұсынған жағдайында, Компания жарияланған ақпараттың мазмұны
және оның (ақпараттың) жариялануымен байланысты болуы мүмкін жағымсыз салдарлар
үшін жауапкершілік көтермейді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.8. Компания жауапкершіліктен босатылады:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.8.1. Жабдықтың, бағдарламалық жасақтаманың, байланыс
арналарының, электрмен жабдықтаудың, интернет желісінің, бағыттау жүйелерінің
жұмысындағы техникалық үзілістер нәтижесінде Мобильді қосымшаға қолжетімділікті
қамтамасыз етуде кез келген кідірістер үшін. Пайдаланушы бағдарлама/ресурстың
жұмысына қатысты қателіктер мен үзілістер болуы мүмкін екенін қабылдайды, бұл
ретте Компания мұндай үзілістердің алдын алу және оларды ақылға қонымды
мерзімде жою үшін шаралар қабылдауға тырысады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.8.2. Мобильді қосымшаның, басқа телекоммуникация желілерінің
жұмысындағы қателіктер үшін, бұл қателіктер бағдарламалық жасақтаманың
кодындағы қателерге, компьютерлік вирустарға және Мобильді қосымшадағы бөгде
кодтардың фрагменттеріне байланысты болса;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.8.3. Пайдаланушының рұқсатынсыз (Пайдаланушының келісімінсіз)
үшінші тұлғалардың Пайдаланушының Логині мен Паролі арқылы Жеке кабинетке кіруі
және басқа деректерге рұқсаты нәтижесінде болған кез келген залалдар үшін.
Пайдаланушының Мобильді қосымшасындағы кез келген сәйкестендіру құралдарының
(оның ішінде, бірақ онымен шектелмей, Логин мен Парольдің) сақталуын қамтамасыз
ету міндеті және жауапкершілігі тікелей Пайдаланушының өзінде;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.8.4. Тауарлардың тұтынушылық қасиеттері, сондай-ақ олардың
заңды түрде жүзеге асырылуы және Серіктеспен орналастырылған ақпараттың
дұрыстығы үшін жауапкершілік көтермейді;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.8.5. Серіктес пен Пайдаланушылар арасында жасалған
келісімшарттар бойынша міндеттемелердің орындалмауы үшін;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.8.6. Мобильді қосымшаның ақпараттық қауіпсіздігін немесе
қалыпты жұмыс істеуін бұзуға бағытталған үшінші тұлғалардың заңсыз әрекеттері
үшін;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.8.7. Интернет желісін және/немесе компьютерлік жабдықты
пайдалануға байланысты басқа да жағдайлар үшін, сондай-ақ Мобильді қосымшаның
жұмыс істеуі және үшінші тұлғалардың әрекеттеріне байланысты кез келген басқа
жағдайлар үшін;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.8.8. Мобильді қосымшада техникалық және профилактикалық
жұмыстар жүргізу үшін.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.9. Пайдаланушының Шартта көрсетілген Банктік және/немесе
пошталық деректерін, электрондық пошта мекенжайларын өзгерту электрондық
хабарлама арқылы Байланыс арнасы арқылы өзгертілгенін хабарлау негізінде жүзеге
асырылады, бұл хабарламаға Пайдаланушы қол қойған хабарламаның сканерленген
көшірмесін қосып, немесе тапсырыс хатпен жіберу немесе тапсыру арқылы
орындалады. Компанияның банктік және/немесе пошталық деректерін, электрондық
пошта мекенжайларын өзгерту туралы хабарлама Мобильді қосымшада орналастырылғаннан
кейін күшіне енеді. Пайдаланушы, егер бұл мүмкіндік Жеке кабинетте бар болса,
өз электрондық пошта мекенжайын өзгертуге құқылы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.10. Компания пайдаланатын электрондық пошта мекенжайы:
info@sorpa.com.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.11. Шартты бұзу туралы хабарлама Тараптардың біреуінің
бастамасы бойынша электрондық немесе жазбаша түрде хабарламаны екінші Тараптың
электрондық поштасына және/немесе Байланыс арнасы арқылы жіберу арқылы, Шартты
бұзу жоспарланған күннен кемінде 30 күнтізбелік күн бұрын жіберілуі мүмкін.
Электрондық пошта арқылы хабарлама жіберілген жағдайда, ол қол қойылған және
сканерленген нұсқасы болып табылады. Шарт егер жіберілген сәттен бастап кемінде
30 күнтізбелік күн өткен болса, немесе хабарламада көрсетілген күннен бастап
күшін жояды, бұл жағдайда егер хабарламада көрсетілген күннен бастап кемінде 30
күнтізбелік күн өткен болса және барлық орындалмаған міндеттемелер болмаған
жағдайда, соңғы есеп айырысулар жүргізілгеннен кейін күшін жояды.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.12. Шарт бұзылғаннан кейін Компанияның Мобильді қосымшаға кіру
мүмкіндігі тоқтатылады. Пайдаланушы Мобильді қосымшаны қайтадан пайдалану үшін
Шартқа қайта қосылу қажет.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>14.13. Тараптар арасында туындаған даулар, келіспеушіліктер және
қарама-қайшылықтарды шешу бойынша соттан тыс реттеу шаралары, талаптарды және
хабарламаларды беру, хат алмасу жолымен шешіледі. Егер дауды соттан тыс реттеу
шаралары нәтижесінде Тараптар келісімге келе алмаса, дау «Астана» Халықаралық
қаржы орталығының сотына беріледі. Шарт мемлекеттік және орыс тілдерінде
жасалған.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:30.0pt;text-indent:-30.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> 15.  <b>Компанияның реквизиттері</b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>ЖШС «Сорпа КЗ»</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>Мекенжайы: Қазақстан, Алматы қаласы, Әуезов ауданы, 6-шағын
аудан, 58-үй, 23-пәтер, A10A6X8 индексі</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>БИН: 240440034473</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>БИК: BRKEKZKA</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>КБе: 19</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>Банк: «Береке Банк» АҚ</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>Шот нөмірі: KZ37914032203KZ025AZ, (KZT)</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>Директор: Тулеубаева Ш.У.</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
font-family:"System Font";color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
font-family:"System Font";color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
font-family:"System Font";color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
font-family:"System Font";color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
font-family:"System Font";color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
font-family:"System Font";color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
font-family:"System Font";color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
font-family:"System Font";color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
font-family:"System Font";color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
font-family:"System Font";color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
font-family:"System Font";color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
font-family:"System Font";color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>Қауымдастық ережелері</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>Ереже Қауымдастықтың жалпы жұмыс шарттарын, Қауымдастыққа
кіруді, Қауымдастыққа қатысуды, Қауымдастық Қатысушыларының құқықтары мен
міндеттерін, Қауымдастық Қатысушыларын ақпараттандыру тәртібі мен шарттарын,
Мобильді қосымшаны пайдаланудың тәртібін, Қауымдастық туралы ақпаратты
орналастырудың тәртібін анықтайды.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>1. Терминдер мен түсініктер</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.1. <b>Ұпайлар</b> – Қолданушының Ұпай шотына жүктелетін
виртуалды шартты бірліктер, Компаниямен Қолданушының Ұпай шотына есептеледі,
сондай-ақ Партнерлерден Тауарларды сатып алғаны үшін Қолданушының Ұпай шотынан
Компаниямен шегеріледі. Ұпайлар Партнер Тауарларына айырбастау құқығын береді.
Партнер Тауарына Ұпайларды айырбастағанда, бір Ұпай бір теңгеге теңестіріледі.
Ұпайлар есепке алу мақсатында пайдаланылады, төлем құралы болып табылмайды және
ақшалай баламада берілмейді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.2. <b>Банк</b> – Партнерді және/немесе Қолданушыны және/немесе
Компанияны қызмет көрсететін екінші деңгейдегі банк.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.3. <b>Байланыс арнасы</b> – Компания және/немесе Партнер
Қолданушыларға (хабарламалар, жарнамалық/ақпараттық сипаттағы хабарламалар,
сондай-ақ Қауымдастықтың жұмыс істеуіне тікелей қатысты хабарламалар) жіберетін
техникалық жүйелер. Ақпаратты электрондық пошта арқылы, мобильді құрылғыға
(смартфон, планшет және т.б.) қысқа мәтіндік хабарламалар (USSD/SMS),
Push-хабарламалар арқылы, Мобильді қосымшада, мессенджерде, телефонда, факс
арқылы, Жеке кабинетке бағыттайды.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.4. <b>Компания</b> – “Сорпа КЗ” ЖШС, Қауымдастықтың
қатысушылары арасында өзара іс-қимыл жасау үшін жауапты және Қауымдастық
аясында жасалатын шарттардың жағдайлары мен ережелерін анықтайды, сондай-ақ
Қауымдастықтың басқару және дамытуға ерекше құқықтарға ие.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.5. <b>Компания әзірлеуші</b> – “Clover Ltd.” жеке компаниясы,
“Sorpa” мобильді қосымшасына арналған ерекше құқықтар кешенін “Сорпа КЗ” ЖШС-не
2024 жылғы 16 мамырдағы №1 Кәсіпкерлік лицензия шарты бойынша берді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.6. <b>Жеке кабинет</b> – Партнерге арналған ақпараттық
кеңістік, оған кіру логин мен пароль енгізу арқылы жүзеге асырылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.7. <b>Мессенджер</b> – мәтіндік хабарламалармен,
аудиожазбалармен, фотосуреттермен және басқа да мультимедиамен алмасуға
арналған бағдарлама немесе қосымша, Тараптар осы Шартты орындау кезінде ақпарат
алмасу мақсатында қолданады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.8. <b>Мобильді қосымша “Sorpa”</b> – Компанияның бағдарламалық
қамтамасыз етуі, мобильді құрылғыда (смартфон, планшет және т.б.) орнатылған
және іске қосылған, Қолданушыларға Партнерлер жүзеге асыратын Тауарларға қол
жеткізуді қамтамасыз етеді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.9. <b>Деректерді өңдеу</b> – Партнерден Қолданушының Тауарды
сатып алуы туралы ақпаратты жинау, талдау, қорытындылау және жүйелеу процесі,
Қолданушының Ұпай шотынан Ұпайларды шегеру, сондай-ақ Партнерден Қолданушы
сатып алған Тауар үшін Компания мен Партнер арасындағы төлемдер туралы
деректерді Мобильді қосымша арқылы өңдеу процесі.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.10. <b>Қауымдастық</b> – Компанияны, Қолданушыларды және
Партнерлерді біріктіретін одақ, ол Қауымдастық шеңберінде жасалған шарттардың
шарттарын орындауды қамтамасыз етеді және Қолданушыларға Партнерлерден Тауарды
Мобильді қосымша арқылы сатып алу мүмкіндігін береді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.11. <b>Партнер</b> – Қолданушыға Қауымдастық шеңберінде
Мобильді қосымша арқылы Тауарды жүзеге асыратын заңды тұлға/жеке кәсіпкер.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.12. <b>Қауымдастық ережелері</b> – Қауымдастыққа қатысу
шарттарын, Қауымдастыққа қосылу ережелері мен тәртібін, Қауымдастық шеңберінде
Қолданушыларды қызмет көрсетуді анықтайтын құжат.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.13. <b>Қолданушы</b> – он сегіз жасқа толған, әрекет
қабілеттілігі шектелмеген, Мобильді қосымшаны мобильді құрылғыға орнату және
оны осы Шартта көзделген тәртіппен ерікті түрде белсендіру арқылы Қауымдастыққа
қосылуға ерікті түрде келісімін білдірген жеке тұлға, сондай-ақ Партнерден
Тауарды сатып алу үшін Мобильді қосымшаны пайдаланатын тұлға. Қолданушы
Компанияға және Партнерлерге Анкета, Өтініштер және басқа да құжаттарда
көрсетілген өзінің жеке деректерін сақтауға және пайдалануға құқық береді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.14. <b>Ұпай шоты</b> – Компанияда сақталатын, Қауымдастықтың
әрбір Қолданушысының пополненных, начисленных және шегерілген Ұпайлар саны
туралы ақпараттық деректердің жиынтығы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.15. <b>Тауар</b> – алкоголь және темекі өнімдерін қоспағанда,
Қазақстан Республикасының заңнамасымен тыйым салынбаған және шектеусіз
айналымға шығарылған қызметтер/жұмыстар/тауарлар. Тауарлардың сапасына және
Қазақстан Республикасының заңнамасымен қойылатын барлық басқа талаптарды сақтауға
жауапкершілік Партнерлерге жүктелген.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.16. <b>Қауымдастықтың қатысушылары</b> – Қауымдастықтың
қатысушылары: Компания, Партнерлер және Қолданушы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>Ережеде қолданылатын және жоғарыда анықталмаған басқа терминдер
мен анықтамалар, Ережеде және/немесе Қауымдастық шеңберінде жасалған шарттарда
көрсетілген мағыналарда және Компаниямен анықталған тәртіп пен шарттарда
түсіндіріледі және түсініледі.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2. Қауымдастыққа қатысу шарттары</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.1. Қауымдастықтың мақсаты – Қолданушылардың адалдығын арттыру,
Партнерлердің тауар нарығын кеңейту және пайдаланушы базасын кеңейту,
Қауымдастық туралы ақпаратты бұқаралық ақпарат құралдарында, оның ішінде
интернетте орналастыру, Қолданушыларға Мобильді қосымша арқылы сатып алу
мүмкіндігін беру.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.2. Қауымдастыққа кіру ерікті негізде, тиісті қосылу шарттарына
қол қою арқылы жүзеге асырылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.3. Қауымдастыққа қатысу келесі артықшылықтарды береді:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.3.1. Партнерге келесі артықшылықтар:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Қолданушылар санын арттыру;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Мобильді қосымша арқылы тауарларды жүзеге асыру;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Қауымдастыққа қатысу ақысыз негізде жүзеге асырылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.3.2. Қолданушыға келесі артықшылықтар:</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Компаниядан қосымша Ұпайлардың есептелуі;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Мобильді қосымша арқылы тауарларды сатып алу;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:13.0pt;text-indent:-13.0pt;line-height:135%;text-autospace:none'><span
lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> •  Мобильді қосымшада және/немесе Байланыс арналарында
Қауымдастық акциялары туралы жеке ұсыныстар алу.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.4. Компания:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.4.1. Партнердің ұсыныстары туралы Қолданушыларды хабардар
етеді, сондай-ақ Қауымдастықты ақпараттық қолдауды және ілгерілетуді жүзеге
асырады. Ақпараттандыру тәртібі Компаниямен анықталады және Байланыс арнасы
арқылы немесе осындай ақпаратты Мобильді қосымшада жариялау арқылы Партнерлер
мен Қолданушыларға жеткізіледі;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.4.2. Қауымдастықты дамыту және мақсаттарға қол жеткізу бойынша
шараларды жүзеге асырады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.4.3. Қауымдастықтың қызметін жарнамалық сүйемелдеуді және
ақпараттық қамтамасыз етуді ұйымдастырады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.4.4. Қауымдастықтың қызметі үшін деректерді өңдеуді жүзеге
асырады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>2.4.5. Жаңа Партнерлер мен Қолданушыларды тарту бойынша жұмыс
жүргізеді;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.4.6.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнерлер мен Қолданушылар алдындағы міндеттемелердің
орындалуын қамтамасыз етеді, Қауымдастық ережелеріне және/немесе Партнермен
және/немесе Қолданушымен жасалған шарттарға толықтырулар мен өзгерістер
енгізеді;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.4.7.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнерлер мен Қолданушылардың Қауымдастық ережелерін
және/немесе Партнермен және/немесе Қолданушымен жасалған шарттарды сақтауын
бақылауды жүзеге асырады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.4.8.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық шеңберінде арнайы ұсыныстар туралы Қолданушыларды
Байланыс арнасы арқылы ақпараттандырады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.4.9.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық шеңберінде жасалған шарттардың шарттарына сәйкес
басқа да іс-әрекеттерді жүзеге асырады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.5. Партнер:</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.5.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Кеңселерінде, Мобильді қосымшада, өз сауда нүктелерінде
Компаниямен келісім бойынша Қауымдастықтың символикасын орналастырады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.5.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Өз қызметкерлеріне Қолданушыларға Мобильді қосымша арқылы
қызмет көрсету шарттарын түсіндіреді;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.5.3.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Тауарды сатуға дейін Партнер (Партнер қызметкері) Қолданушыда
Мобильді қосымша бар екеніне көз жеткізуге міндетті;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.5.4.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық шеңберінде жасалған шарттарға сәйкес
міндеттемелерді тиісті түрде орындайды;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.5.5.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қолданушының Тауарды сатып алуы кезінде Партнер Мобильді
қосымшаны қабылдауға міндетті;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.5.6.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Егер мұндай төлем жасалған шарттарда көзделген болса,
Компанияға төлем жасайды;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.5.7.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық шеңберінде өздерінің арнайы ұсыныстары туралы
Қолданушыларды Байланыс арнасы арқылы хабардар етеді;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.5.8.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық шеңберінде жасалған шарттардың шарттарына сәйкес
басқа да іс-әрекеттерді жүзеге асырады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.6. Партнерге тыйым салынады:</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.6.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қолданушының жеке деректерін Қауымдастық қызметімен байланысты
емес мақсаттарда өз қалауы бойынша пайдалану/тарату;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.6.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қолданушыларға Мобильді қосымша арқылы жүзеге асырылатын
Тауарлар үшін басқа төлем әдістеріне қарағанда жоғары баға қою, соның ішінде
жеңілдіктерді (акцияларды) ескере отырып;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.6.3.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Мобильді қосымша арқылы есеп айырысу кезінде Тауарды жүзеге
асыру үшін Қолданушылардан қосымша төлем алу;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.6.4.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Тауарды жүзеге асыру барысында Қолданушыға Тауардың орнына ақша
беру;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.6.5.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық шеңберінде жасалған шарттардың шарттарына сәйкес
басқа да іс-әрекеттерді жүзеге асыру.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.7. Қолданушы:</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.7.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық шеңберінде ұсынылатын арнайы ұсыныстар туралы Компаниядан
және/немесе Партнерден Байланыс арнасы арқылы ақпарат алады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.7.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық шеңберінде жасалған шарттарға сәйкес
міндеттемелерді тиісті түрде орындайды;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.7.3.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Егер Партнер Мобильді қосымша арқылы Тауарды жүзеге асырудан
негізсіз бас тартса, Қолданушы осыған байланысты Компанияға жазбаша немесе
Байланыс арнасы арқылы электрондық түрде шағыммен жүгінуге құқылы;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.7.4.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Компанияның сұрауы бойынша Тауарды сатып алуды растайтын
құжаттарды (қажет болған жағдайда Тауарды да) ұсынады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.7.5.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық шеңберінде жасалған шарттардың шарттарына сәйкес
басқа да іс-әрекеттерді жүзеге асырады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.8. Қолданушыға тыйым салынады:</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.8.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Мобильді қосымша орнатылған мобильді құрылғыны басқа біреуге
беру, Қауымдастыққа қатысушы ретінде Жеке кабинетке тіркелу кезінде пайдаланған
құпия деректерді, оның ішінде Логин мен Парольді, басқа біреуге беру;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.8.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнерден Тауардың орнына ақша алу немесе сұрау;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.8.3.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнерлермен, олардың қызметкерлерімен немесе басқа
тұлғалармен келісілген немесе өз бетінше, алаяқтық ретінде бағалануы мүмкін
немесе Қауымдастық ережелерін және Қауымдастық шеңберінде жасалған шарттарды
бұзуға әкелетін іс-әрекеттерді жүзеге асыру, оның ішінде Ұпайларды ақшалай
қаражатқа айырбастау мақсатында (Ұпай шоты бойынша жалған операциялар жүргізу),
Қауымдастық ережелерін және Қауымдастық шеңберінде жасалған шарттарды айналып
өтіп, артықшылықтар мен жеңілдіктер алу үшін келісілген, жалған мәмілелер
жасау;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.8.4.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық шеңберінде жасалған шарттардың шарттарына сәйкес
басқа да іс-әрекеттерді жүзеге асыру.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3. Қауымдастыққа қатысушы тұлғаларға
қойылатын талаптар</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3.1. Партнерлерге қойылатын талаптар:</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3.1.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Мобильді қосымша арқылы Қолданушыға Тауарды жүзеге асыратын
заңды тұлға/жеке кәсіпкер;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3.1.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Мобильді қосымшаны орнатуға техникалық мүмкіндігі бар мобильді
құрылғының болуы;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3.1.3.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қазақстан Республикасының резиденттері;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3.1.4.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Мобильді қосымшада Партнер ретінде ақпаратты орналастыруға
келісім беру;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3.1.5.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қолданушының Тауарды Мобильді қосымша арқылы сатып алуы кезінде
жүзеге асыру;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3.1.6.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық пен/немесе Партнермен жасалған шарттарда көзделген
басқа талаптарды орындау.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3.2. Қолданушыға қойылатын талаптар:</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3.2.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Он сегіз жасқа толған, әрекет қабілеттілігі шектелмеген жеке
тұлға;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3.2.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Мобильді қосымшаны мобильді құрылғыға орнату және оны
Қолданушының белсендіруі;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3.2.3.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қазақстан Республикасының резиденті;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3.2.4.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық пен/немесе Қолданушымен жасалған шарттарда
көзделген басқа талаптарды орындау.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>4. Ережеге өзгерістер мен толықтырулар
енгізу тәртібі</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>4.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Ереже Мобильді қосымшада жарияланады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>4.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Компания Қауымдастық шеңберінде Партнермен немесе Қолданушымен
жасалған шарттарға біржақты тәртіпте өзгерістер енгізуге құқылы. Ережеге
және/немесе Қауымдастық шеңберінде Партнермен немесе Қолданушымен жасалған
шарттарға енгізілген өзгерістермен жаңа редакциясы Мобильді қосымшада кемінде
30 (отыз) күн бұрын жарияланады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>4.3.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнер және/немесе Қолданушы Ережеге және/немесе Қауымдастық
шеңберінде жасалған шарттарға енгізілген өзгерістерді өздігінен қадағалауға
міндетті.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>4.4.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Компания Ережені, Қауымдастық шеңберінде Партнермен және/немесе
Қолданушымен жасалған шарттарды, сондай-ақ басқа да құжаттарды (егер бар
болса), сондай-ақ барлық өзгерістерді енгізу күнінен бастап 3 (үш) жыл бойы
сақтауды жүзеге асырады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>5. Қауымдастықтан шығу тәртібі</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>5.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастықтан шығу тәртібі Қауымдастық шеңберінде Партнермен
және Қолданушымен жасалған шарттарда қарастырылған, бұл ретте Қауымдастықтан
шығу Партнер немесе Қолданушының бастамасымен, сондай-ақ Компанияның
бастамасымен жүзеге асырылуы мүмкін.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>5.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнер және/немесе Қолданушы Қауымдастық шеңберінде жасалған
шартты бұзу арқылы кез келген уақытта Қауымдастықтан шығуға құқылы, сондай-ақ
шартта белгіленген мерзімдер мен шарттарда басқа жағдайларда шығуға құқылы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>5.3.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Компания Партнердің және/немесе Қолданушының Қауымдастыққа
қатысуын тоқтатуға біржақты тәртіпте құқылы, бірақ бұл жағдайда Партнер
және/немесе Қолданушының шарттарды орындамауы және/немесе тиісінше орындамауы
және/немесе орындаудан бас тартуы негізінде ғана жүзеге асырылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>5.4.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнер, Қауымдастықтан шыққан жағдайда, қандай да бір негіздер
бойынша, Қауымдастықтың барлық символикасын алып тастауға, өз веб-сайтынан және
жарнамалық материалдардан тиісті ақпаратты жоюға міндетті.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6. Жауапкершілік</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Егер Партнер/Қолданушы Қауымдастық шеңберінде берілген
құқықтарды теріс пайдаланса, бұл Қауымдастық ережелерін немесе Қауымдастық
шеңберінде жасалған шарттарды бұзса, Компания Қауымдастық ережелерінде
және/немесе Қауымдастық шеңберінде жасалған шарттарда қарастырылған мөлшерде
айыппұл төлеуді талап етуге және Қауымдастық ережелерінде және шарттарда
қарастырылған басқа да шараларды қолдануға құқылы. Бұл жағдайда теріс пайдалану
деп – Партнердің және/немесе Қолданушының Қауымдастық ережелерін және/немесе
Қауымдастық шеңберінде жасалған шарттарды орындамауға немесе тиісінше
орындамауға бағытталған әділетсіз әрекеттері түсініледі.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық ережелерін және/немесе Қауымдастық шеңберінде
жасалған шарттарды бұзғаны үшін Компания Қолданушыдан әрбір бұзушылық фактісі
үшін шарттарда белгіленген мөлшерде айыппұл (айыппұл санкциялары) талап ете
алады және оны Қолданушының Ұпай шотынан шегеруі мүмкін.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.3.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Егер Партнердің қызметкерлері Қолданушыға қызмет көрсетуден
негізсіз бас тартса, Партнер мұндай бас тартуды болдырмау шараларын қабылдауға
міндетті.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.4.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнер, Мобильді қосымшаны көрсеткен Қолданушыға қызмет
көрсетуден бас тартқан жағдайда және өз әрекеттерін негіздемеген жағдайда,
Компанияның бастамасымен Қауымдастықтан шығарылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.5.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қолданушының айыппұлды (айыппұл санкцияларын) төлеуі оны тиісті
шарт бойынша міндеттемелерді орындаудан босатпайды.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.6.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық ережелерін және/немесе Қауымдастық шеңберінде
жасалған шарттарды бұзғаны үшін Компания Қолданушыға келесі жауапкершілік
шараларының біреуін немесе бірнешеуін қолдана алады:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.6.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Мобильді қосымшаны бұғаттау;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.6.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Компаниямен есептелген Ұпайларды жою;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.6.3.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастыққа қатысуды тоқтату.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.7.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнерге Қауымдастық ережелерін бұзғаны үшін Қауымдастық
шеңберінде жасалған шарттарда қарастырылған жауапкершілік шараларының бірі
қолданылуы мүмкін, мысалы:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.7.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастыққа қатысуды тоқтату;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.7.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Жүйеге және Мобильді қосымшаға қол жеткізуді бұғаттау.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.8.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Компания Партнер/Қолданушының жеке ақпаратты үшінші тұлғалар
пайдалануы нәтижесінде туындаған кез келген шығындар үшін жауапкершілік
көтермейді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.9.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнер/Қолданушы өзінің Жеке кабинетін пайдаланып немесе
Партнер/Қолданушының атынан Мобильді қосымшада орындалған кез келген әрекеттер
үшін жауап береді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.10.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қолданушы өзінің Мобильді құрылғысының, Жеке кабинетке кіру
үшін Логин мен Парольдің сақталуы үшін өзі жауапты болады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.11.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнер Тауарлардың сапасы мен Қазақстан Республикасының
заңнамасында қойылатын барлық басқа талаптарды орындау үшін өзі жауапты болады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.12.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Компания келесі жағдайларда жауапкершілік көтермейді:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.12.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Жүйеге және Мобильді қосымшаға қол жеткізуді қамтамасыз етудегі
техникалық үзілістер, жабдықтың, бағдарламалық жасақтаманың істен шығуы,
Байланыс арналарындағы үзілістер, электр қуатының үзілістері, Интернет
желісіндегі үзілістер, бағыттау жүйелері мен домендік атаулар жүйесіндегі
ақаулар, DDoS-шабуылдардың немесе интернет-ресурстар мен Байланыс арналарының
жұмысын бұзатын басқа да заңсыз әрекеттердің орын алуынан туындаған үзілістер
үшін. Партнер немесе Қолданушы бағдарламалық жасақтаманың/ресурстың жұмысында
қателер мен ақаулар болуы мүмкін екенін түсінеді, сонымен қатар Компания мұндай
үзілістерді болдырмау және оларды ақылға қонымды мерзімде жою үшін шаралар
қолдануға тырысады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.12.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Мобильді қосымшаның жұмысындағы ақаулар, телекоммуникация
желілеріндегі басқа ақаулар, кодтағы қателер, компьютерлік вирустар және
Мобильді қосымша бағдарламалық жасақтамасындағы басқа да сыртқы код
фрагменттерінен туындаған ақаулар үшін;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.12.3.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Үшінші тұлғалардың Жеке кабинетке және Мобильді қосымшадағы
басқа мәліметтерге рұқсатсыз (Партнердің немесе Қолданушының рұқсатынсыз) қол
жеткізуі үшін. Мобильді қосымшадағы Партнердің немесе Қолданушының кез келген
сәйкестендіру құралдарын (оның ішінде, бірақ олармен шектелмей, Логин мен
Пароль) сақтау және қорғау Партнердің немесе Қолданушының міндеті және
жауапкершілігі болып табылады;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.12.4.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Тауарлардың тұтынушылық қасиеттері үшін, сондай-ақ олардың
сатылымға шығарылу заңдылығы үшін, Партнер Мобильді қосымшада орналастырған
ақпараттың шынайылығы және оның заңдылығы үшін;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.12.5.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнер мен Қолданушылар арасында Тауарға қатысты жасалған
шарттар бойынша міндеттемелердің орындалуы үшін;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.12.6.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Мобильді қосымшаның ақпараттық қауіпсіздігін немесе қалыпты
жұмысын бұзуға бағытталған үшінші тұлғалардың заңсыз әрекеттері үшін;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.12.7.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Интернет желісін және/немесе қолданыстағы компьютерлік
жабдықтарды пайдалануды нашарлатуға бағытталған үшінші тұлғалардың әрекеттеріне
байланысты басқа жағдайлар үшін, сондай-ақ Мобильді қосымшаның жұмыс
қабілеттілігіне және үшінші тұлғалардың әрекеттеріне қатысты кез келген басқа
әрекеттер үшін;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.12.8.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Мобильді қосымшада техникалық және профилактикалық жұмыстарды
жүргізу үшін.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.13.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Егер Партнер/Қолданушы жалған, дұрыс емес немесе заңсыз ақпарат
ұсынса, Компания орналастырылған ақпараттың мазмұны және оның орналастырылуына
байланысты кез келген жағымсыз салдарлар үшін жауапкершілік көтермейді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.14.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнер/Қолданушы Қауымдастық ережелерінің талаптарын білмеуі
Компанияның Қауымдастық шеңберінде Партнер/Қолданушыға шағымдар қою құқығынан
босатпайды.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>7. Жеке деректерді өңдеу</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>7.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастыққа қосыла отырып, Қолданушы өз еркімен және өз
бастамасымен Компанияға және Партнерлерге келесі жеке деректерін ұсынады:
фамилия, аты, әкесінің аты, жынысы, туған күні, мобильді құрылғы нөмірі,
электрондық пошта, тұратын қала мен көше және Мобильді қосымшаны белсендіру
кезінде Анкетада көрсетілген басқа да деректер. Компания және Партнерлер
аталған жеке деректерді автоматтандырылған және автоматтандырылмаған түрде
өңдеуге құқылы, соның ішінде оларды жинау, жазу, жүйелеу, жинақтау, сақтау,
нақтылау (жаңарту, өзгерту), алу, пайдалану, беру (тарату, ұсыну, қол жеткізу),
бейтараптандыру, блоктау, жою және жою. Қолданушы өз деректерін өңдеуге
келісімін Қауымдастық қызметінің мақсаттарына сәйкес береді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>7.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қолданушы берген жеке деректерді өңдеудің негізгі мақсаты
Қауымдастыққа қатысуды қамтамасыз ету, соның ішінде Тауарларды сатуға арналған
жеке ұсыныстарды қалыптастыру болып табылады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>7.3.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қолданушы ұсынылған жеке деректерді жоғарыда аталған мақсаттар
үшін өңдеу, оның ішінде ұсынылған жеке деректерді қатаң құпиялылық шарттарында
Қауымдастықтың тапсырысы бойынша Партнерлерге (олардың тізімі Мобильді
қосымшада келтірілген) беру (қол жеткізу) арқылы жүзеге асырылатынын біледі
және келіседі.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>7.4.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Компания Қолданушылардың жеке деректерін жоғарыда көрсетілген
жағдайларды қоспағанда, олардың пайдаланылуымен байланысты болған кезде
жарияламауға міндеттенеді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>7.5.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қолданушы Қауымдастыққа қосылған кезде Компанияға және
Партнерлерге берген жеке деректерін өңдеуге келісімін Қауымдастықтың қызмет ету
мерзімі ішінде береді, егер Қазақстан Республикасының қолданыстағы заңнамасында
өзгеше көзделмесе, және кез келген уақытта Компанияға жазбаша хабарлама жіберу
арқылы өзінің келісімін кері қайтарып алуға құқылы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>8. Қорытынды ережелер</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>8.1.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қауымдастық шеңберінде Партнермен және/немесе Қолданушымен
жасалған шарттар белгісіз мерзімге (мерзімсіз) жасалады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>8.2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Компания Қауымдастықтың қызметін кез келген уақытта тоқтата
алады (тоқтатады), бұл туралы Партнерлер мен Қолданушыларды қызметті тоқтатудан
(тоқтатудан) 20 (жиырма) күнтізбелік күн бұрын хабардар етеді, тиісті
хабарламаны Мобильді қосымша арқылы немесе Байланыс құралы арқылы жіберу
арқылы. Қауымдастықтың қызметі тоқтатылған кезең ішінде және Қауымдастықтың
қызметі тоқтатылған күннен бастап (егер Қауымдастықтың қызметі тоқтатылған
жағдайда) Компания Ұпайларды толықтыруды, есептеуді және шегеруді тоқтатады,
сондай-ақ Қауымдастық қызметін орындау бойынша өз міндеттемелерін тоқтатады.
Егер Қауымдастықтың қызметі тоқтатылғаннан кейін және Қауымдастықтың қызметі
тоқтатылған сәттен бастап 20 (жиырма) күнтізбелік күн ішінде Қолданушылардың
Ұпайлары пайдаланылмаған болса, Қолданушылардың Ұпайлары толық көлемде
жойылады, бұл ретте Компания мен Партнерлер Қолданушыларға ешқандай төлемдер
мен өтемақылар жасамайды.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>9. Компанияның деректемелері</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>ЖШС «Сорпа КЗ»</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>Мекенжайы: Қазақстан, Алматы қаласы, Әуезов ауданы, 6-шы ықшам
аудан, 58 үй, 23 пәтер, пошта индексі A10A6X8</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>БСН: 240440034473</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>БИК: BRKEKZKA</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>Кбе: 19</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>Банк: «Береке Банк» АҚ</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>Есепшот нөмірі: KZ37914032203KZ025AZ, (KZT)</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>Директор: Төлеубаева Ш.У.</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'><br>
<br>
</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>Тауарға қатысты Қолданушының шағымдарын
қарау ережелері</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>Осы Қолданушының Тауарға қатысты шағымдарын қарау ережелері
(бұдан әрі – Ережелер) Қауымдастық ережелері мен Қауымдастық шеңберінде
жасалатын шарттардың ажырамас бөлігі болып табылады және Компания мен Партнер
арасындағы Қолданушының Тауарға қатысты шағымдарын қарау тәртібін реттейді.
Тауарға қатысты дауларды қарау тәртібі Компаниямен Ережелерге сәйкес
анықталады.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>1. Терминдер мен анықтамалар:</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.1. <b>Ұпайлар</b> – Қолданушының Ұпай шотына жүктелетін
виртуалды шартты бірліктер, Компаниямен Қолданушының Ұпай шотына есептеледі,
сондай-ақ Партнерлерден Тауарларды сатып алғаны үшін Қолданушының Ұпай шотынан
Компаниямен шегеріледі. Ұпайлар Партнер Тауарларына айырбастау құқығын береді.
Партнер Тауарына Ұпайларды айырбастағанда, бір Ұпай бір теңгеге теңестіріледі.
Ұпайлар есепке алу мақсатында пайдаланылады, төлем құралы болып табылмайды және
ақшалай баламада берілмейді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.2. <b>Байланыс арнасы</b> – Компания және/немесе Партнер
Қолданушыларға (хабарламалар, жарнамалық/ақпараттық сипаттағы хабарламалар,
сондай-ақ Қауымдастықтың жұмыс істеуіне тікелей қатысты хабарламалар) жіберетін
техникалық жүйелер. Ақпаратты электрондық пошта арқылы, мобильді құрылғыға
(смартфон, планшет және т.б.) қысқа мәтіндік хабарламалар (USSD/SMS),
Push-хабарламалар арқылы, Мобильді қосымшада, мессенджерде, телефонда, факс
арқылы, Жеке кабинетке бағыттайды.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.3. <b>Компания</b> – “Сорпа КЗ” ЖШС, Қауымдастықтың
қатысушылары арасында өзара іс-қимыл жасау үшін жауапты және Қауымдастық
аясында жасалатын шарттардың жағдайлары мен ережелерін анықтайды, сондай-ақ
Қауымдастықтың басқару және дамытуға ерекше құқықтарға ие.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.4. <b>Компания әзірлеуші</b> – “Clover Ltd.” жеке компаниясы,
“Sorpa” мобильді қосымшасына арналған ерекше құқықтар кешенін “Сорпа КЗ” ЖШС-не
2024 жылғы 16 мамырдағы №1 Кәсіпкерлік лицензия шарты бойынша берді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.5. <b>Мобильді қосымша</b> – Компанияның бағдарламалық
қамтамасыз етуі, мобильді құрылғыда (смартфон, планшет және т.б.) орнатылған
және іске қосылған, Қолданушыларға Компания мен Партнерлердің тауарларды жүзеге
асыру бойынша қызметтеріне қол жеткізуді қамтамасыз етеді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.6. <b>Қауымдастық</b> – Компанияны, Қолданушыларды және
Партнерлерді біріктіретін одақ, ол Қауымдастық шеңберінде жасалған шарттардың
шарттарын орындауды қамтамасыз етеді және Қолданушыларға Партнерлерден Тауарды
Мобильді қосымша арқылы сатып алу мүмкіндігін береді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.7. <b>Партнер</b> – Қолданушыға Қауымдастық шеңберінде
Мобильді қосымша арқылы Тауарды жүзеге асыратын заңды тұлға/жеке кәсіпкер.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.8. <b>Қауымдастық ережелері</b> – Қауымдастыққа қатысу
шарттарын, Қауымдастыққа қосылу ережелері мен тәртібін, Қауымдастық шеңберінде
Қолданушыларға қызмет көрсетуді анықтайтын құжат.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.9. <b>Қолданушы</b> – он сегіз жасқа толған, әрекет қабілеттілігі
шектелмеген, Мобильді қосымшаны мобильді құрылғыға орнату және оны осы Шартта
көзделген тәртіппен ерікті түрде белсендіру арқылы Қауымдастыққа қосылуға
ерікті түрде келісімін білдірген жеке тұлға, сондай-ақ Партнерден Тауарды сатып
алу үшін Мобильді қосымшаны пайдаланатын тұлға. Қолданушы Компанияға және
Партнерлерге Анкета, Өтініштер және басқа да құжаттарда көрсетілген өзінің жеке
деректерін сақтауға және пайдалануға құқық береді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>1.10. <b>Тауар</b> – Қазақстан Республикасының заңнамасымен
тыйым салынбаған және шектеусіз айналымға шығарылған
тауарлар/қызметтер/жұмыстар. Тауарлардың сапасына және Қазақстан
Республикасының заңнамасымен қойылатын барлық басқа талаптарды сақтауға
жауапкершілік Партнерлерге жүктелген.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>2.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Қолданушылардың адалдығын сақтау мақсатында Компания
Қолданушылардан келіп түскен, Компанияға бағытталған Тауардың сапасына қатысты
шағымдарды (бұдан әрі – Шағым) Ережелерге сәйкес қарауға қатысуға құқылы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>3.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Компания қарауға қатысуға құқылы болатын Шағымның мазмұны
келесі жағдайларға қатысты болуы мүмкін:</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>3.1. Қолданушыға сапасыз Тауар беру (ақау, кемшілік, орау
зақымы);</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>3.2. Қолданушыға Партнер ұсынған ақпаратқа және Мобильді
қосымшада көрсетілген ақпаратқа сәйкес келмейтін Тауар беру;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><span lang=RU style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'>3.3. Компанияның қалауы бойынша басқа да жағдайлар.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>4.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Компания қарауға қабылдайтын Шағымдар Партнер мен Қолданушы
арасында дербес шешілмеген және Қолданушының Партнерден сатып алынған сапасыз
Тауар үшін сатып алу-сату шартын орындаудан бас тартуы туралы растама қамтитын
шағымдар болуы мүмкін.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>5.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Шағымды алғаннан кейін Компания жағдайларды анықтау мақсатында
тексеруді бастайды және жүргізеді. Тексеру барысында Компания қажетті ақпаратты
қолжетімді көздерден, соның ішінде Қолданушы мен Партнерден алуы мүмкін.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>6.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Тексеру жүргізу барысында Компания Партнердің Қолданушыға
сапасыз Тауар бергенін немесе орау талаптарын сақтамау салдарынан зақымдалған
Тауар бергенін анықтауға (мойындауға) құқылы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>7.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Өткізілген тексеру нәтижелері бойынша Компания Шағымның
негізділігі және Партнердің шарттық міндеттемелерін, соның ішінде Ережелерді
бұзу туралы шешімді біржақты тәртіпте қабылдайды.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>8.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Компания қабылдаған шешім туралы Партнер мен Қолданушыны
Байланыс арналарымен хабардар етеді.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>9.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Егер Компания Қолданушының Шағымын негізді деп шешсе, Партнер
сапасыз Тауар үшін Қолданушыға Ұпайларды қайтаруға және Тауарды қайтаруға
құқылы.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:135%;text-autospace:
none'><b><span lang=RU style='font-size:14.0pt;line-height:135%;font-family:
"Times New Roman",serif;color:#0E0E0E'>10.</span></b><span lang=RU
style='font-size:14.0pt;line-height:135%;
color:#0E0E0E'> Партнер Компанияның хабарламасында көрсетілген талаптарды толық
көлемде орындауға міндетті.</span></p>

<p class=MsoNormal><b><span lang=RU style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'>11.</span></b><span lang=RU
style='font-size:14.0pt;line-height:115%;
color:#0E0E0E'> Компания Шағымдарды қарауға қатысқанда өз атынан әрекет етеді,
Қолданушы немесе Партнердің мүдделерін білдірмейді және олардың атынан әрекет
етпейді.</span></p>`}}/>
                )
        }

    return (
        <div className={"container"} style={{gap: 12}}>
            <p style={{fontSize: "16px", fontWeight: "bold"}}>ДОГОВОР ОФЕРТЫ</p>
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Общие положения</p>
            Настоящий Договор (для Пользователя) является официальной публичной офертой (предложением) Компании,
            адресованной всем заинтересованным Пользователям, заключили на определенных в настоящем Договоре условиях об
            участии в Объединении.
            <br/>Предметом настоящего Договора является возможность Пользователей пользоваться поощрениями,
            предоставляемыми Компанией и Партнерами, заключившими договор с Компанией на присоединение к Объединению.
            Компания и Партнеры предоставляют Пользователям поощрения за совершаемые в торговых точках Партнеров
            приобретение Товаров посредством Мобильного приложения.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Термины и определения</p>
            Активация Мобильного приложения – совершение последовательных действий, описанных в пункте 3.2.
            Договора. Мобильное приложение считается активированным в случае получения Системой анкетных данных
            Пользователя (анкетные данные поступают в Систему в течение 1 (Одного) рабочего дня после заполнения),
            отмеченных в Мобильном приложении как обязательные для активации Мобильного приложения; прикрепления к
            Мобильному приложению актуального номера мобильного устройства Пользователя, активирующего Мобильное
            приложение.
            <br/>Анкета – форма, которую заполняет Пользователь для участия в Объединении. Является добровольной
            передачей персональных данных Пользователем Компании и Партнерам. Анкета заполняется Пользователем в
            Мобильном приложении.
            <br/>Баланс Счета Баллов – суммарный результат Транзакций Баллов с момента Активации Мобильного приложения
            по текущий момент времени.
            <br/>Баллы – виртуальные условные единицы, пополняемые Пользователем на Счет Баллов, начисляемые Компанией
            на Счет Баллов Пользователя, а также списываемые Компанией со Счетов Баллов Пользователя за приобретение
            Товаров у Партнеров. Баллы дают право на приобретение Товара путем обмена Баллов на Товар Партнера. При
            обмене Баллов на Товар Партнера один Балл приравнивается к одному тенге. Баллы используются в учетных целях,
            не являются средством платежа, не могут быть выданы в денежном эквиваленте.
            <br/>Банк – банк второго уровня, осуществляющий обслуживание Пользователя и/или Партнера и/или Компанию.
            <br/>Возвратная операция – операция начисления Баллов на Счет Баллов Пользователя в случае возврата
            Пользователем Товара, приобретенного с использованием Мобильного приложения в Торговой точке Партнеров, если
            при таком приобретении проводилась операция по Списанию Баллов.
            <br/>Договор – настоящий Договор между Компанией и Пользователем, опубликованный в Мобильном приложении,
            условия которого определены Компанией и могут быть приняты Пользователем в целом не иначе как путем
            присоединения к Договору в соответствии со статьей 389 Гражданского Кодекса Республики Казахстан.
            <br/>Канал связи – система технических средств, с помощью которых Компания и/или Партнер информируют
            (направляют уведомления, объявления, сообщения, непосредственно связанных с функционированием Объединения, а
            также объявления и сообщения рекламного/информационного характера) Пользователей, путем направления
            информации по Email (электронная почта), на мобильное устройство (смартфон, планшет и др.) в виде коротких
            текстовых сообщений (USSD/SMS), Push-уведомлений, в Мобильное приложение, по мессенджеру, телефону, факсу, в
            Личный кабинет.
            <br/>Компания– ТОО «Сорпа КЗ», организует обеспечение взаимодействия между Участниками Объединения,
            определяет Положение об Объединении и условия договоров, заключаемых в рамках Объединения, а также условия
            участия в Объединении. Компания обладает исключительными правами на управление и развитие Объединения.
            <br/>Компания Разработчик – Частная Компания «Clover Ltd.», передавшая право использования в
            предпринимательской деятельности комплекса исключительных прав на мобильное приложение «Sorpa», ТОО «Сорпа
            КЗ» на основании Договора №1 о комплексной предпринимательской лицензии от 16.05.2024г.
            <br/>Личный кабинет – персональная страница Пользователя, доступная в Мобильном приложении, доступ к
            которому осуществляется посредством ввода логина и пароля, на которой содержится информация о Пользователе,
            о балансе Счета Баллов Пользователя, история Транзакций Пользователя, совершенных Пользователем с
            использованием Мобильного приложения, и прочая информация, размещенная в рамках Объединения.
            <br/>Мессенджер – программа или приложение, предназначенные для мгновенного обмена текстовыми сообщениями,
            аудиозаписями, фотографиями и другими мультимедиа, используемые Сторонами для целей обмена информацией при
            исполнении Договора.
            <br/>Мобильное приложение «Sorpa» (Мобильное приложение) – программное обеспечение Компании, установленное,
            запущенное на мобильном устройстве (смартфон, планшет и др.), предоставляющее доступ Пользователям к
            Товарам, реализуемых Партнерами.
            <br/>МРП – месячный расчетный показатель, применяется в Положении как мера ответственности Пользователя.
            Размер МРП устанавливается Законом Республики Казахстан о республиканском бюджете на соответствующий период;
            <br/>Начисление Баллов - операции, в результате которых производится увеличение количества Баллов на Счете
            Баллов по инициативе Компании.
            <br/>Объединение – союз, включающий в себя Компанию, Пользователей и Партнеров для исполнения условий
            договоров, заключенных в рамках Объединения, предоставляющих возможность Пользователям приобретать у
            Партнеров Товар посредством Мобильного приложения.
            <br/>Партнер – юридическое лицо/индивидуальный предприниматель, реализующие Товар Пользователю в рамках
            Объединения посредством Мобильного приложения.
            <br/>Положение об Объединении (Положение) – документ, определяющий условия участия в Объединении, правила и
            порядок присоединения к Объединению, обслуживания Пользователей в рамках Объединения;
            <br/>Пользователь – физическое лицо, достигшие восемнадцати лет не ограниченное в дееспособности, выразившие
            добровольное согласие на присоединение к Объединению, путем установления Мобильного приложения на мобильное
            устройство и его добровольной активации в предусмотренном Договором порядке, и/или использующего Мобильное
            приложение для приобретения Товара у Партнера. Пользователь предоставляет Компании и Партнерам право хранить
            и использовать свои персональные данные, указываемые Пользователем в Анкете, Заявлениях и иных документах.
            <br/>Пополнение Баллов - операции, в результате которых производится увеличение количества Баллов на Счете
            Баллов по инициативе Пользователя.
            <br/>Система – совокупность программно-аппаратных средств, обеспечивающих информационное взаимодействие
            между Участниками Объединения.
            <br/>Списание Баллов - операции, в результате которых производится списание (уменьшение) Баллов со Счета
            Баллов Пользователя, в т. ч. безакцептное списание Баллов, а также за счет приобретения Товаров у Партнеров.
            <br/>Сторона или Стороны – Компания и/или Пользователь.
            <br/>Счет Баллов – совокупность хранящихся у Компании информационных данных о количестве пополненных,
            начисленных и списанных Баллов, а также о текущем балансе Счета Баллов в отношении каждого Пользователя
            Объединения.
            <br/>Товар – услуги/работы/товары (за исключением алкогольной и табачной продукции) не запрещенные и не
            ограниченные законодательством Республики Казахстан к обороту. Ответственность за качество Товаров и
            соблюдение всех иных к ним требований, предъявляемых законодательством Республики Казахстан, возложена на
            Партнеров, реализующих данные Товары.
            <br/>Торговые точки - территориально обособленные подразделения (магазины, торговые объекты, места
            обслуживания и т. п.) Партнеров, реализующих Товары.
            <br/>Транзакции – операции, совершаемые Пользователем по Счету Баллов (пополнение, начисление и списание
            Баллов) с использованием Мобильного приложения.
            <br/>Участники Объединения - участниками Объединения являются: Компания, Партнеры и Пользователь.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Порядок заключения Договора</p>
            Договор опубликован в Мобильном приложении и считается заключенным в момент акцепта Пользователем
            оферты.
            <br/>Акцептом оферты (принятием предложения заключить настоящий Договор) является факт совершения
            Пользователем одного из следующих действий:
            <br/>Первое использование Мобильного приложения в момент совершения приобретения Товара у Партнера;
            <br/>Активация Мобильного приложения с помощью Мобильного приложения посредством его регистрации (позволит
            потенциальному Пользователю использовать Личный кабинет в Мобильном приложении с использованием Логина
            (номер мобильного устройства или Email) и Пароля (пароль предоставляется при активации Мобильного приложения
            направленного на номер мобильного устройства или на Email). При регистрации в Мобильном приложении
            Пользователь заполняет предлагаемую Анкету.
            <br/>Пользователь, совершив Акцепт, считается подтвердившим, что он:
            <br/>целиком и полностью ознакомлен и согласен с условиями Договора;
            <br/>использует Мобильное приложение исключительно для личных целей, не связанных с предпринимательской
            деятельностью, и такие цели не противоречат действующему законодательству Республики Казахстан.
            <br/>С момента совершения Пользователем хотя бы одного из действий, указанных в пункте 3.2. Договора
            Пользователь становится Участником Объединения. Подтверждением совершения указанных действий (принятием
            Договора) является отражение соответствующих действий в журнале операций Системы.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Порядок вступления в Объединение</p>
            Участие в Объединении Пользователей является добровольным.
            <br/>Участником Объединения – Пользователем, может быть только физическое лицо, которому на момент
            регистрации в Объединении исполнилось 18 (восемнадцать) лет, получающее Товар исключительно для себя,
            несвязанных с осуществлением предпринимательской деятельности.
            <br/>Любое дееспособное физическое лицо становится Пользователем с момента совершения акцепта Договора и
            момента активации Мобильного приложения в соответствии с Договором.
            <br/>Договор заключаются путем присоединения Пользователя к данному Договору в виде акцепта публичного
            предложения, размещенного в Мобильном приложении.
            <br/>На один номер мобильного устройства может быть активировано только одно Мобильное приложение.
            Пользователь может изменить номер мобильного устройства, на который активировано Мобильное приложение
            посредством личного кабинета в Мобильном приложении.
            <br/>Совершая действия, указанные в пункте 3.2. Договора, направленные на присоединение к Объединению, а
            также действия, связанные с начислением, списанием Баллов в рамках Объединения, Пользователь подтверждает,
            что он ознакомился и согласен с условиями Договора, тем самым принимает на себя обязательства по нему и
            обязуется соблюдать его без изъятий и оговорок в полном объеме. Подтверждением совершения указанных действий
            является отражение соответствующих действий в журнале операций Системы.
            <br/>Компания вправе отказать потенциальному Пользователю в участии в Объединении в случае отсутствия
            технической или иной возможности для этого на момент обращения, а также в случае некорректного запроса на
            активацию Мобильного приложения или несоблюдением всех необходимых требований для активации Мобильного
            приложения, предусмотренных условиями активации Мобильного приложения.
            <br/>В рамках Объединения Пользователь использует Баллы для приобретения Товара у Партнера.
            <br/>Пользователь имеет возможность воспользоваться Баллами посредством Мобильного приложения, только при
            соблюдении условий, предусмотренных Договором, а также в случае использования активированного Мобильного
            приложения. В случае блокировки Мобильного приложения Пользователь не имеет права воспользоваться Баллами
            посредством Мобильного приложения в течение всего срока блокирования Мобильного приложения.
            <br/>Пользователь обязан обеспечить конфиденциальность данных, использованных им при регистрации в Личном
            кабинете в качестве участника Объединения, в том числе Логина и Пароля, необходимых для доступа в Личный
            кабинет. Компания и Партнеры не несут какой бы то ни было ответственности за ущерб, причиненный Пользователю
            в результате несанкционированного доступа третьих лиц к Личному кабинету Пользователя с использованием
            Логина и Пароля Пользователя в случаях компрометации Логина и Пароля Пользователем.
            <br/>Пользователь несет ответственность за достоверность данных, указанных при присоединении к Объединению,
            а также за своевременное обновление этих данных.
            <br/>Номер мобильного устройства, использованный Пользователем при регистрации в Объединении, будет
            автоматически авторизован на управление Счета Баллов Пользователя;
            <br/>Пользователь автоматически исключается из Объединения, а Мобильное приложение блокируется в случае
            отсутствия операций по пополнению и/или, начислению и/или списанию Баллов со Счета Баллов Пользователя от 12
            (двенадцати) и более календарных месяцев с даты совершения последней операции, а также в случае отзыва
            Пользователем согласия на обработку своих персональных данных, а также поступления соответствующего
            Заявления в соответствии с условиями настоящего Договора.
            <br/>В случае прекращения участия Пользователя в Объединении по любому основанию все Баллы со Счета Баллов
            соответствующего Пользователя списываются в безакцептном порядке, Мобильное приложение блокируется,
            информация о Пользователе удаляется из Системы.
            <br/>Баллы, пополненные и/или начисленные на Счет Баллов Пользователя ошибочно, в том числе в результате
            сбоя в работе оборудования, программного обеспечения, аннулируются Компанией без уведомления Пользователя.
            Аннулированные по установленным Договором основаниям Баллы восстановлению не подлежат.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Мобильное приложение и его обслуживание</p>
            Мобильное приложение является собственностью Компании. Информация, размещенная в Мобильном приложении,
            должна использоваться Пользователем для получения особых условий обслуживания у Партнера и не может быть
            передана Пользователем другому лицу. Все риски связанные с передачей мобильного устройства Пользователем, на
            котором установлено Мобильное приложение, другому лицу несет Пользователь.
            <br/>В случае утраты, кражи или повреждения мобильного устройства, на котором установлено Мобильное
            приложение, Пользователь обязан сообщить об этом Компании по посредством Каналов связи. После получения
            сообщения Компания блокирует такое Мобильное приложение, а Пользователь вправе от Компании бесплатно
            получить доступ к Мобильному приложению через другое мобильное устройство с сохранением в нем всей
            информации и Баллов при условии подтверждения принадлежности доступа к такому Мобильному приложению. При
            этом Компания не несет ответственности за несанкционированное использование Мобильного приложения
            Пользователя третьими лицами.
            <br/>Приобретение Товара у Партнера посредством Мобильного приложения отражается в Системе.
            <br/>Товары, полученные с использование Мобильного приложения, подлежат возврату либо обмену в торговых
            точках Партнера в соответствии с действующим законодательством Республики Казахстан.
            <br/>Компания имеет право заблокировать Мобильное приложение Пользователя в следующих случаях:
            <br/>Нарушения Пользователем Договора, Положения и иных документов, регламентирующих условия участия в
            Объединении;
            <br/>Поступления в Компанию претензий от других Пользователей, Партнёров и/или третьих лиц о нарушении
            Пользователем Договора, Положения и иных документов, регламентирующих условия участия в Объединении;
            <br/>Выявления деятельности Пользователя, запрещенной действующим законодательством Республики Казахстан;
            <br/>Отказа Пользователя от предоставления документов, идентифицирующих его как Участника Объединения, при
            возникновении необходимости у Компании идентифицировать Пользователя.
            <br/>Пользователь вправе получить информацию об Объединении одним из следующих способов:
            <br/>через Мобильное приложение;
            <br/>по Каналам связи.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Система</p>
            В Системе обращается информация, введенная в Систему Пользователем, обрабатываемая Компанией и
            Партнерами, предназначенная для целей информационного взаимодействия между Участниками Объединения.
            <br/>В Системе предусмотрено разграничение доступа к информации на уровне взаимодействия между Пользователем
            и Компанией/Партнерами. Каждый Пользователь имеет доступ только к той информации, которая имеет отношение к
            непосредственной деятельности данного Пользователя в Объединении, и к информации, введенной данным
            Пользователем в Систему.
            <br/>При введении информации в Систему Пользователь под свою полную и законом установленную ответственность
            гарантирует, что у него есть все законные основания для использования такой информации в Системе, то есть
            Пользователь либо является носителем (источником), собственником данной информации, либо, на законных
            основаниях такая информация была предоставлена ему в пользование другими носителями (собственниками,
            источниками) информации.
            <br/>Пользователь, вводящий информацию в Систему, самостоятельно и под свою ответственность получает все
            согласования и разрешения, которые могут быть необходимы для ввода такой информации, несет ответственность
            за правильность и достоверность информации, введенной в Систему, а также за оперативность её ввода.
            <br/>Пользователь дает согласие на передачу информации в электронной форме заявления (в том числе
            персональных данных) по Каналам связи и/или сети Интернет.
            <br/>Компания и Партнеры имеют право использовать информацию, обращаемую в Системе, только в соответствии с
            целями и на условиях, предусмотренных Договором и Положением.
            <br/>Информация о физическом лице, введенная Пользователем в Систему, относящаяся к определенному либо
            определяемому на основании такой информации физическому лицу, относится к персональным данным и
            обрабатывается в соответствии с законодательством Республики Казахстан.
            <br/>Компания и Партнеры обязуются обеспечивать конфиденциальность информации, ставшей им известной при
            использовании Системы, и разрешать минимально необходимый доступ к данной информации только тем работникам,
            которые непосредственно связаны с обслуживанием Пользователя, предварительно разъяснив данным работникам их
            обязанность соблюдать конфиденциальность в отношении указанной информации, и ответственность за ее
            разглашение, установленную законодательством Республики Казахстан.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Права и обязанности Участников Объединения</p>
            Полномочия Копании по организации и управлению Объединением:
            <br/>Организовать привлечение Пользователей;
            <br/>Определять порядок информационно-технического взаимодействия между Участниками Объединения;
            <br/>Выполнять работу по активации, блокировке и разблокировке Мобильного приложения в случае обращения
            Пользователя. По требованию Пользователя предоставлять информацию по Транзакциям с использованием Мобильного
            приложения данного Пользователя;
            <br/>Информировать Пользователей о предложениях, Товарах, проводимых мероприятиях и пр. путем Каналов связи;
            <br/>Осуществлять поддержку Участников в рамках Объединения по всем организационным вопросам;
            <br/>Не разглашать информацию о Пользователе и суммах платежей, кроме случаев, когда действующим
            законодательством Республики Казахстан предусмотрена обязанность по предоставлению такого рода информации,
            уполномоченному на то государственному органу, а также, если такая информация является общедоступной или
            раскрывается на основании разрешения самого Пользователя;
            <br/>Активировать Мобильное приложение, при условии соблюдения требований Договора.
            <br/>В рамках Объединения Компания и Партнеры имеют право:
            <br/>Предоставлять Пользователям информацию, непосредственно связанную с работой Объединения. Для издания и
            размещения таких материалов Компания и Партнеры вправе по собственной инициативе, а также в соответствии с
            требованиями действующего законодательства Республики Казахстан размещать дополнительную информацию, в том
            числе рекламного характера, на документах, выдаваемых Пользователям и иных документах, формируемых с
            использованием Системы;
            <br/>Для осуществления деятельности Объединения Компания праве привлекать третьих лиц;
            <br/>Компания вправе приостановить (прекратить) деятельность Объединения в любое время с уведомлением
            Пользователей за 20 (Двадцать) календарных дней до даты приостановления (прекращения), путем направления
            соответствующего уведомления через Мобильное приложение либо Средством связи. В течение периода, на который
            деятельность Объединения будет приостановлена, а также с даты прекращения деятельности Объединения (в случае
            прекращения деятельности Объединения) Компания не производит пополнение, начисление и списание Баллов, а
            также приостанавливает/прекращает исполнение своих обязательств по деятельности Объединения. В случае
            прекращения деятельности Объединения и неиспользования Пользователями Баллов в течение 20 (Двадцати)
            календарных дней с момента размещения уведомления о прекращении деятельности Объединения в Мобильном
            приложении либо Средством связи, Баллы Пользователей аннулируются в полном объеме, при этом Компания и
            Партнеры не осуществляют никаких выплат и компенсаций в пользу Пользователей.
            <br/>Компания и Партнеры обязуется обслуживать Пользователей на условиях, предусмотренных Договором и
            Положением. Перечень торговых точек Партнеров, на которых производится обслуживание Пользователей,
            размещается Компанией в Мобильном приложении. Компания и Партнеры обязуются обеспечить соблюдение прав всех
            Пользователей, предусмотренных действующим законодательством Республики Казахстан, при передаче им Товаров,
            осуществляемых в рамках Объединения, в объеме не меньшем, нежели потребителям, получающим Товар на обычных
            условиях.
            <br/>Обязательства Пользователя:
            <br/>Выполнять условия настоящего Договора и Положения;
            <br/>Заботиться должным образом о сохранности мобильного устройства, на котором установлено Мобильное
            приложение. В случае передачи мобильного устройства, на котором установлено Мобильное приложение, третьим
            лицам, нести солидарную ответственность за действия третьих лиц по использованию Мобильного приложения. Не
            использовать Мобильное приложение в противоправных целях, нарушающих права третьих лиц, а также в целях
            совершения приобретения Товаров с целью осуществления предпринимательской деятельности и/или запрещенных к
            продаже в соответствии с законодательством Республики Казахстан;
            <br/>Пользователь обязан ознакомиться с условиями приобретения Товара у Партнера;
            <br/>Пользователь обязан самостоятельно отслеживать внесение изменений и дополнений в настоящий Договор,
            Положения, а также в условия обслуживания Мобильного приложения Компании, размещаемые в Мобильном
            приложении. Совершение Пользователем транзакций с использованием Мобильного приложения после вступления в
            силу указанных изменений, является подтверждением согласия Пользователя с внесенными изменениями (новой
            редакцией);
            <br/>Исполнять иные обязательства, связанные с участием Пользователя в Объединении.
            <br/>В рамках Объединения Пользователь вправе:
            <br/>Пользоваться дополнительными сервисами и услугами, предусмотренными для Пользователей;
            <br/>Получать информацию о Транзакциях и иную информацию, имеющую значение для использования Мобильного
            приложения в течение срока участия Пользователя в Объединении;
            <br/>По своему усмотрению в рамках возможностей, предоставляемых Мобильным приложением использовать,
            пополненные и начисленные Баллы. Информацию о полном перечне Товаров доступных Пользователю можно получить
            через Мобильное приложение, а также у каждого из Партнеров, предоставляющих их;
            <br/>Пользователь вправе прекратить своё участие в Объединении в одностороннем порядке в случае несогласия с
            условиями Договора и Положения, либо с внесенными в Договор и Положение изменениями. В случае добровольного
            прекращения Пользователем участия в Объединении, Баллы, находящиеся на Счете Баллов Пользователя, на дату
            прекращения участия в Объединении списываются в безакцептном порядке, Мобильное приложение блокируется,
            информация о Пользователе удаляется из Системы;
            <br/>Фактом добровольного прекращения участия в Объединении со стороны Пользователя считается:
            <br/>Передача (или направления посредством почты, курьером, заказным письмом в адрес) Компании
            соответствующего письменного Заявления Пользователя, написанного в произвольной форме с обязательным
            указанием Ф.И.О. Пользователя и номера телефона; Не использование Мобильного приложения Пользователем в
            течение 12 (Двенадцати) месяцев с момента последней операции по данному Мобильному приложению.
            <br/>Присоединяясь к Объединению, Пользователь самостоятельно и по своей инициативе предоставляет Компании и
            Партнерам следующие свои персональные данные: фамилия, имя, отчество, пол, дата рождения, номер мобильного
            устройства, Email, город и улица проживания и иные данные, указанные в Анкете при активации Мобильного
            приложения. Компания и Партнеры имеют право осуществлять автоматизированную и неавтоматизированную обработку
            указанных персональных данных, в том числе их сбор, запись, систематизацию, накопление, хранение, уточнение
            (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение. Пользователь предоставляет согласие на обработку своих
            персональных данных в целях:
            <br/>обеспечения нормальной деятельности Объединения в соответствии с настоящим Договором и Положением;
            <br/>выполнения Компанией и Партнерами обязательств, вытекающих из настоящего Договора и Положения;
            <br/>оценки и анализа деятельности Объединения;
            <br/>получения Пользователем от Компании уведомлений и информации, связанных с деятельностью Объединения;
            <br/>получения Пользователем рекламных/информационных объявлений Компании и Партнеров.
            <br/>Пользователь предоставляет Компании и Партнерам право передавать свои персональные данные третьим лицам
            в целях:
            <br/>обеспечения функционирования Объединения;
            <br/>дополнительного информирования Пользователя о специальных предложениях в рамках Объединения;
            <br/>выполнения Компанией и Партнерами своих обязательств, вытекающих из Договора;
            <br/>выполнения договорных обязательств между Компанией, Партнерами и третьими лицами.
            <br/>Согласие Пользователя на обработку персональных данных, предоставленное Компании и Партнерам при
            присоединении к Объединению, действует в течение всего срока деятельности Объединения, если иное не
            предусмотрено действующим законодательством Республики Казахстан, и может быть отозвано Пользователем в
            любой момент путем направления письменного уведомления об отзыве согласия в адрес Компании.
            <br/>Присоединяясь к Объединению, Пользователь выражает свое безусловное согласие на получение от Компании и
            Партнеров по Каналам связи уведомлений, объявлений, сообщений, непосредственно связанных с функционированием
            Объединения, а также объявлений и сообщений рекламного/информационного характера.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Условия пополнения и начисления Баллов Пользователям</p>
            Пополнение Пользователем Счета Баллов осуществляется через Мобильное приложение посредством платежных
            систем и Банка из расчета 1:1 (один Балл к одному тенге).
            <br/>Оплата денежных средств может быть осуществлен одним из следующих способов:
            <br/>-оплата банковской картой Клиента с использованием Сервиса и Платежной системы;
            <br/>-оплата банковской картой Клиента через системы мобильных платежей (Apple Pay, Google Pay и/или Samsung
            Pay).
            <br/>В случае если банковская карта Клиента поддерживает технологию 3D Secure, для осуществления перевода
            необходимо будет пройти дополнительную проверку пользователя в банке-эмитенте. Для этого Клиент будет
            направлен на страницу банка, выдавшего карту. Порядок проверки определяется банком, выдавшим карту.
            <br/>При пополнении Пользователем Счета Баллов Компания начисляет Пользователю Баллы в следующем размере:
            <br/>8.4.1. При пополнении Пользователем Счета Баллов на сумму от 1 000,00 тенге до 9 999,00 тенге, Компания
            дополнительно начисляет 5% Баллов от суммы пополнения;
            <br/>8.4.2. При пополнении Пользователем Счета Баллов на сумму от 10 000,00 тенге до 49 999,00 тенге,
            Компания дополнительно начисляет 10% Баллов от суммы пополнения;
            <br/>8.4.3. При пополнении Пользователем Счета Баллов на сумму от 50 000,00 тенге до 99 999,00 тенге,
            Компания дополнительно начисляет 15% Баллов от суммы пополнения;
            <br/>8.4.4. При пополнении Пользователем Счета Баллов на сумму от 100 000,00 тенге и более, Компания
            дополнительно начисляет 20% Баллов от суммы пополнения.
            <br/>После пополнения Пользователем Счета Баллов и начисления Компанией Баллов Пользователь не вправе
            выводить Баллы со Счета Баллов на банковский счет в Банке и/или переводить на Счет Баллов другого
            Пользователя.
            <br/>Все операции по пополнению и начислению Баллов производятся в Системе и передаются на Мобильное
            приложение Партнеров. Взаимодействие (обмен информацией) между Системой и Мобильным приложением Партнеров
            производится в режиме on-line.
            <br/>Пополнение и начисление Баллов на Счет Баллов Пользователя с последующем отражением в Мобильном
            приложении, Компания осуществляет не более 24 часа с момента совершения пополнения Счета Баллов
            Пользователем.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Условия списания Баллов</p>
            Баллы используются в учетных целях и не являются средством платежа, в связи с чем, не могут быть выданы
            в денежном эквиваленте.
            <br/>При списании Баллов для приобретения на Товар у Компании количество списываемых Баллов определяется из
            расчета 1:1 (один Балл к одному тенге).
            <br/>При списании Баллов для приобретения на Товар у Пользователя в первую очередь списанию подлежат
            дополнительно начисленные Баллы, находящиеся на Счете Баллов Пользователя. Основное количество Баллов
            подлежат списанию по окончании списывания дополнительно начисленных Баллов..
            <br/>Компания имеет право в любой момент заблокировать операции по списанию Баллов со Счета Баллов
            посредством Мобильного приложения Пользователя в случае нарушения Пользователем условий Договора и
            Положения, а также в случае усмотрения/обнаружения мошеннических действий при операциях с Мобильным
            приложением Пользователя.
            <br/>Компания вправе произвести безакцептное списание Баллов со Счета Баллов Пользователя в следующих
            случаях:
            <br/>В случае ошибочного пополнения и/или начисления Баллов Компанией, в том числе и по причине технического
            сбоя как в работе программного обеспечения, так и в работе Каналов связи, а также иных технических сбоев,
            производится безакцептное списание ошибочно пополненных и/или начисленных Баллов со Счета Баллов
            Пользователя;
            <br/>В случае мошеннических действий и/или недобросовестных действий и/или бездействий Пользователя и/или
            третьих лиц, производится безакцептное списание всех Баллов, находящихся на Счете Баллов Пользователя;
            <br/>В случае, если с момента последней Транзакции по Счету Баллов прошло более 30 (Тридцати) календарных
            дней, без акцептного списания подлежат только дополнительно начисленные Баллы, находящиеся на Счете Баллов
            Пользователя;
            <br/>В случае прекращения деятельности Объединения по инициативе Компании, в том числе и третьей стороны,
            либо в силу изменений в действующем законодательстве Республики Казахстан, производится безакцептное
            списание Баллов со всех Счетов Баллов Пользователей;
            <br/>В случае прекращения участия Пользователя в Объединении по любому основанию все Баллы со Счета Баллов
            данного Пользователя списываются в безакцептном порядке;
            <br/>Количество Баллов, списываемых в безакцептном порядке со Счетов Баллов Пользователя, не может превышать
            остатка Баллов на Счету Баллов Пользователя на момент списания.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Проведение возвратных операций</p>
            10.1. При возврате Товара, приобретенного Пользователем, возврат Баллов Пользователю производится в
            размере, принятом к оплате данного Товара Компанией по указанию Партнера на Счет Баллов Пользователя.
            <br/>10.3. В случае возможности совершения операций по обмену Товара на аналогичный либо его замене на иной
            Товар у Партнера, по желанию Пользователя, такой обмен/замена возвращаемого Товара допускается. В любом
            случае возможность обмена/замены Товара определяется и производится в соответствии с действующим
            законодательством Республики Казахстан.
            <br/>10.4. Ответственность за осуществление Возвратной операции по Счету Баллов Пользователя на основании
            возврата Товара Пользователем, а также ответственность за начисления Баллов на Счет Баллов Пользователя
            возлагается на Компанию.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Порядок урегулирования претензий Пользователей</p>
            В случае, если Пользователь обратится к Компании, с претензией, связанной с пополнением и/или
            начислением Баллов на свой Счет Баллов, а также с любой иной претензией, связанной с использованием
            Мобильного приложения, Компания обязуется приложить все усилия для оперативного выяснения причин
            возникновения таких претензий. Если причинами возникновения претензий явились ошибки, возникающие в Системе
            по вине Компании, либо технические сбои на стороне Компании, Компания обязуется собственными силами и за
            свой счет устранить данные причины и урегулировать претензии Пользователя.
            <br/>Если причинами возникновения претензий явились ошибки, действия и/или бездействия со стороны Партнеров,
            либо третьих лиц, за действия которых несет ответственность Партнеры, либо технические сбои на стороне
            Партнеров, Компания направляет данную претензию в адрес Партнера с указанием предполагаемых причин ошибок.
            Компания либо Партнер обязуется за свой счет устранить данные причины и приложить все усилия для
            урегулирования претензии Пользователя в срок не более 10 (Десяти) календарных дней с момента получения такой
            претензии. По согласованию между Компанией и Партнером устранение ошибок, повлекших претензию Пользователя,
            может осуществляться Компанией либо Партнером собственными силами, либо силами третьих лиц, компетентных в
            устранении данных ошибок.
            <br/>Устранение причин и последствий претензий Пользователя, а также оплата всех расходов, связанных с
            урегулирование претензии производится за счет Компании и/или Партнера, виновной(го) в возникновении таких
            причин. В случае обоюдной вины Компания и Партнер несут солидарную ответственность по устранению причин и
            урегулированию претензии Пользователя. Во всех случаях солидарной ответственности бремя компенсации за ущерб
            и урегулирование претензии, а также устранение причин и последствий, распределяется между Компанией и
            Партнером соразмерно степени их вины. Если степень вины Компании и Партнера установить невозможно, то бремя
            компенсации распределяется между ними поровну.
            <br/>Ответственность Компании перед Пользователем за нарушение условий Договора в случае любой претензии,
            связанной с пополнением и/или начислением и/или списанием Баллов на/с Счет(а) Баллов Пользователя,
            ограничивается начислением надлежащего количества Баллов на Счет Баллов Пользователя.
            <br/>В случае, если Пользователь обратится к Компании, с претензией в отношении качества приобретенного
            Товара, Компания данную претензию Пользователя рассматривает в рамках Правил рассмотрения претензий
            Пользователя в отношении Товара.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Форс-мажорные обстоятельства</p>
            Компания и Партнеры освобождаются от ответственности за частичное или полное неисполнение обязательств,
            предусмотренных Договором и Положением, в случае возникновения обстоятельств непреодолимой силы, влияющих на
            исполнение Компанией и Партнерами своих обязательств, возникших вследствие событий чрезвычайного характера,
            которые невозможно было ни предвидеть, ни предотвратить разумными мерами.
            <br/>К обстоятельствам непреодолимой силы относятся события, на которые Компания и Партнеры не могут оказать
            влияния и за возникновение которых они не несут ответственности, таких как землетрясения, наводнения,
            ураганы и другие стихийные бедствия, а также войны, военные действия, террористические акты, пожары, аварии,
            а также постановления или распоряжения органов государственной власти и управления.
            <br/>В случае возникновения обстоятельств непреодолимой силы срок исполнения обязательств отодвигается на
            срок действия таких обстоятельств и их последствий. В случае возникновения спора о моменте начала и
            окончания действия обстоятельств непреодолимой силы, он разрешается путем предоставления документального
            подтверждения сроков этих обстоятельств, выданных соответствующими компетентными государственными органами
            Республики Казахстан.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Порядок внесения изменений и дополнений в Договор и
                Положение</p>
            Компания вправе в одностороннем порядке вносить изменения в настоящий Договор и Положение. Новая
            редакция Договора и Положения с внесенными изменениями размещаются Компанией в Мобильном приложении, не
            менее чем за 30 (тридцать) дней до вступления их в силу.
            <br/>В случае несогласия Пользователя на участие в Объединении в соответствии с новой редакцией Договора
            и/или Положения, Пользователь прекращает свое участие в Объединении в порядке, предусмотренном настоящим
            Договором.
            <br/>С момента вступления новой редакции Договора и/или Положения в силу все Пользователи Объединения
            руководствуются Договором и Положением в новой редакции. Все зарегистрированные ранее Пользователи,
            продолжая свое участие в Объединении, тем самым выражают свое безоговорочное согласие с новой редакцией
            Договора и/или Положения, а также дают согласие на обработку своих персональных данных Компанией и
            Партнерами.
            <br/>Каждый Пользователь, заключая Договор с Компанией полностью понимает и признает одностороннее право
            Компании изменять настоящий Договор и Положение, условия участия в Объединении, и обязуется принимать
            участие в работе Объединения на измененных условиях.
            <br/>Участники обязуются самостоятельно отслеживать внесение изменений в Договор и Положение.
            <br/>Компания осуществляет хранение Договора, Положения и иных документов (при наличии таковых), а также
            всех их изменений в течение 3 (трех) лет с момента внесения соответствующих изменений.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Заключительные положения</p>
            Договор заключается Сторонами на неопределенное время (является бессрочным).
            <br/>Условия Договора определены Компанией и могут быть приняты Пользователем в целом не иначе как путем
            присоединения к Договору в соответствии со статьей 389 Гражданского Кодекса Республики Казахстан.
            <br/>За нарушение Договора и/или Положения, Компания вправе начислить штраф (неустойку) в размере до 1000
            МРП и списать его со Счета Баллов Пользователя.
            <br/>За нарушение Договора и/или Положения, Компания к Пользователю, помимо оплаты штрафа (неустойки) вправе
            применить одно или несколько следующих мер ответственности:
            <br/>Блокировка Мобильного приложения;
            <br/>Отмена начисленных Компанией Баллов;
            <br/>Прекращение участия в Объединении;
            <br/>Компания не несет ответственность за любые рода убытки, причиненные Пользователю в результате
            использования третьими лицами учетной и иной информации, ставшей доступной третьим лицам не по вине
            Компании.
            <br/>Пользователь несет ответственность за любые действия, осуществленные с использованием Мобильного
            приложения в его Личном кабинете/с использованием Личного кабинета от имени Пользователя.
            <br/>В случае предоставления Партнером/Пользователем недостоверной, неправильной или неправомерной
            информации, Компания не несет ответственности за содержание размещаемой информации и за возможные
            неблагоприятные последствия, связанные с её (информации) размещением.
            <br/>Компания не несет ответственности за:
            <br/>Перерывы в предоставлении доступа к Мобильному приложению по причине технических перебоев в работе
            оборудования, программного обеспечения, перебоев в работе Каналов связи, перебоев в электропитании, перебоев
            в работе сети Интернет, сбоев систем маршрутизации и/или в системе доменных имен, осуществления DDoS-атак и
            иных противоправных действий, нарушающих работоспособность интернет-ресурсов и Каналов связи. Пользователь
            соглашается с тем, что возможны ошибки и сбои, в том числе в отношении работы программного
            обеспечения/ресурса, обеспечивающих работу Мобильного приложения, при этом Компания будет стараться
            принимать разумные меры для предотвращения таких перебоев, их устранения в разумные сроки;
            <br/>Сбоев в работе Мобильного приложения, в иных сетях телекоммуникаций, вызванных ошибками в коде,
            компьютерными вирусами и иными посторонними фрагментами кода в программном обеспечении Мобильного
            приложения;
            <br/>Неавторизованный (т. е. без разрешения Пользователя) доступ третьих лиц к Личному кабинету, и иным
            данным в Мобильном приложении, доступ к которым осуществляется с помощью Логина и Пароля Пользователя.
            Ответственность за сохранность любых средств идентификации Пользователя в Мобильном приложении (в том числе,
            но не ограничиваясь, Логина и Пароля) является обязанностью и ответственностью непосредственно Пользователя;
            <br/>Потребительские свойства Товаров, а также за правомерность выставления их на реализацию, за
            достоверность информации, размещаемой Партнером в Мобильном приложении и правомерность ее размещения;
            <br/>Нарушение обязательств по договорам, заключаемым в отношении Товара между Партнером и Пользователями.
            <br/>Неправомерных действий третьих лиц, направленных на нарушение информационной безопасности или
            нормального функционирования Мобильного приложения;
            <br/>Других случаев, связанных с действиями (бездействием) третьих лиц, направленными на ухудшение общей
            ситуации с использованием сети Интернет и/или компьютерного оборудования, существующего в течение срока
            деятельности Объединения, также любых других действий, связанных с работоспособностью Мобильного приложения
            и действия третьих лиц;
            <br/>Выполнения технических и профилактических работ в Мобильном приложении.
            <br/>Изменение банковских и/или почтовых реквизитов, адресов электронной почты Пользователя, отраженных в
            Договоре, происходит на основании уведомления о таких изменениях электронным сообщением по Каналам связи, с
            вложением отсканированной копии уведомления, подписанного Пользователем, либо направления подлинника
            подписанного уведомления по почте заказным письмом или его вручения нарочно представителю Стороны с
            проставлением им отметки о получении на копии. Изменение банковских и/или почтовых реквизитов, адресов
            электронной почты Компании, отраженных в Договоре, осуществляется посредством размещения данной информации в
            Мобильном приложении. Пользователь также может произвести изменение адреса электронной почты посредством
            Личного кабинета при наличии такой опции.
            <br/>Электронные адрес, используемый Компанией (адрес электронной почты): Info@sorpa.com.
            <br/>Расторжение Договора возможно по инициативе одной из Сторон путем направления уведомления о расторжении
            Договора в письменной или электронной форме на электронный адрес другой Стороны и/или по Каналам связи не
            менее чем за 30 календарных дней до предполагаемой даты расторжения. Уведомление в электронной форме должно
            содержать отсканированную копию письма о расторжении Договора, подписанного Пользователем. Договор считается
            расторгнутым по истечении 30 календарных дней с даты направления уведомления, либо с даты, которая указана в
            таком уведомлении, при условии, что с момента направления уведомления до наступления указанной в нем даты
            расторжения прошло не менее 30 календарных дней, а также при условии отсутствия всех неисполненных
            обязательств и проведения окончательного взаиморасчета.
            <br/>После расторжения Договора предоставление доступа к Мобильному приложению Компании прекращается.
            Дальнейшее использование Мобильного приложения Пользователем возможно только после повторного присоединения
            к Договору.
            <br/>Стороны Договора, решили, что в случае возникновения между ними споров, противоречий и разногласий,
            принимают меры по внесудебному урегулированию спора путем проведения претензионной работы, направление
            требований и уведомлений, обмен письмами. При недостижении Сторонами согласия в результате принятых мер по
            внесудебному урегулированию спора, спор передается на разрешение суда Международного финансового центра
            «Астана». Договор составлен на государственном и русском языках.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Реквизиты компании</p>
            Наименование: ТОО «Сорпа КЗ»
            <br/>Адрес: Казахстан, город Алматы, Ауэзовский район, Микрорайон 6, дом 58, кв. 23, почтовый индекс A10A6X8
            <br/>БИН: 240440034473
            <br/>КБе: 17
            <br/>Банк: AO «Bereke Bank»
            <br/>Номер счета: K737914032203KZ025AZ, (KZT)
            <br/>Директор: Тулеубаева Ш.У.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Положение об Объединении</p>
            Положение определяет общие условия работы Объединения, вступление в Объединение, участие в Объединении,
            права и обязанности Участников Объединения, порядок и условия информирования Участников Объединения, порядок
            пользования Мобильным приложением, порядок размещения информации об Объединении.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Термины и понятия</p>
            Баллы – виртуальные условные единицы, пополняемые Пользователем на Счет Баллов, начисляемые Компанией
            на Счет Баллов Пользователя, а также списываемые Компанией со Счетов Баллов Пользователя за приобретение
            Товаров у Партнеров. Баллы дают право на приобретение Товара путем обмена Баллов на Товар Партнера. При
            обмене Баллов на Товар Партнера один Балл приравнивается к одному тенге. Баллы используются в учетных целях,
            не являются средством платежа, не могут быть выданы в денежном эквиваленте.
            <br/>Банк – банк второго уровня, осуществляющий обслуживание Партнера и/или Пользователя и/или Компанию.
            <br/>Канал связи – система технических средств, с помощью которых Компания и/или Партнер информируют
            (направляют уведомления, объявления, сообщения, непосредственно связанных с функционированием Объединения, а
            также объявления и сообщения рекламного/информационного характера) Пользователей, путем направления
            информации по Email (электронная почта), на мобильное устройство (смартфон, планшет и др.) в виде коротких
            текстовых сообщений (USSD/SMS), Push-уведомлений, в Мобильное приложение, по мессенджеру, телефону, факсу, в
            Личный кабинет.
            <br/>Компания – ТОО «Сорпа КЗ», организует обеспечение взаимодействия между Участниками Объединения,
            определяет Положение и условия договоров, заключаемых в рамках Объединения, а также условия участия в
            Объединении. Компания обладает исключительными правами на управление и развитие Объединения.
            <br/>Компания Разработчик – Частная Компания «Clover Ltd.», передавшая право использования в
            предпринимательской деятельности комплекса исключительных прав на мобильное приложение «Sorpa», ТОО «Сорпа
            КЗ» на основании Договора №1 о комплексной предпринимательской лицензии от 16.05.2024г.
            <br/>Личный кабинет – информационное пространство Партнера, выделенное в Мобильном приложении, доступ к
            которому осуществляется посредством ввода логина и пароля.
            <br/>Мессенджер – программа или приложение, предназначенные для мгновенного обмена текстовыми сообщениями,
            аудиозаписями, фотографиями и другими мультимедиа, используемые Сторонами для целей обмена информацией при
            исполнении Договора.
            <br/>Мобильное приложение «Sorpa» (Мобильное приложение) - программное обеспечение Компании, установленное,
            запущенное на мобильном устройстве (смартфон, планшет и др.), предоставляющее доступ Пользователям к
            Товарам, реализуемых Партнерами.
            <br/>Обработка данных – процесс сбора, анализа, обобщения и систематизации информации о приобретении Товара
            Пользователем у Партнера, списания Баллов со Счета Баллов Пользователя, а также о платежах между Компанией и
            Партнером за приобретенный у Партнера Пользователем Товар посредством Мобильного приложения.
            <br/>Объединение – союз, включающий в себя Компанию, Пользователей и Партнеров для исполнения условий
            договоров, заключенных в рамках Объединения, предоставляющих возможность Пользователям приобретать у
            Партнеров Товар посредством Мобильного приложения.
            <br/>Партнер – юридическое лицо/индивидуальный предприниматель, реализующие Товар Пользователю в рамках
            Объединения посредством Мобильного приложения.
            <br/>Положение об Объединении (Положение) – настоящий документ, определяющий условия участия в Объединении,
            правила и порядок присоединения к Объединению, обслуживания Пользователей в рамках Объединения.
            <br/>Пользователь – физическое лицо, достигшие восемнадцати лет не ограниченное в дееспособности, выразившие
            добровольное согласие на присоединение к Объединению, путем установления Мобильного приложения на мобильное
            устройство и его добровольной активации в предусмотренном Договором порядке, и/или использующего Мобильное
            приложение для приобретения Товара у Партнера. Пользователь предоставляет Компании и Партнерам право хранить
            и использовать свои персональные данные, указываемые Пользователем в Анкете, Заявлениях и иных документах.
            <br/>Счет Баллов – совокупность хранящихся у Компании информационных данных о количестве пополненных,
            начисленных и списанных Баллов, а также о текущем балансе Счета Баллов в отношении каждого Пользователя
            Объединения.
            <br/>Товар – услуги/работы/товары (за исключением алкогольной и табачной продукции) не запрещенные и не
            ограниченные законодательством Республики Казахстан к обороту. Ответственность за качество Товаров и
            соблюдение всех иных к ним требований, предъявляемых законодательством Республики Казахстан, возложена на
            Партнеров, реализующих данные Товары.
            <br/>Участники Объединения - участниками Объединения являются: Компания, Партнеры и Пользователь.
            <br/>Иные термины и определения, используемые в Положении и не определенные выше, определяются и понимаются
            в значениях, указанных в Положении и/или договорах, заключаемых в рамках Объединения, в порядке и на
            условиях, определяемых Компанией.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Условия участия в Объединении</p>
            Целью Объединения является увеличение лояльности Пользователей, расширение рынка сбыта Товара Партнеров
            и пользовательской базы, размещение сведений об Объединении в средствах массовой информации, в том числе
            Интернете, предоставления Пользователям возможности осуществить покупки посредством Мобильного приложения.
            <br/>Вступление в Объединение осуществляется на добровольной основе путем заключения на определенных
            условиях соответствующие договора присоединения.
            <br/>Участие в Объединении предоставляет:
            <br/>Партнеру следующие привилегии:
            <br/>Увеличение Пользователей;
            <br/>Реализация Товара посредством Мобильного приложения;
            <br/>Участие в Объединении осуществляется на безвозмездной основе.
            <br/>Пользователю следующие привилегии:
            <br/>Начисление Компанией дополнительных Баллов;
            <br/>Приобретение Товара посредством Мобильного приложения;
            <br/>Получение персональных предложений об акциях Объединения в Мобильном приложении и/или по Каналам связи.
            <br/>Компания:
            <br/>Информирует Пользователей о предложениях Партнера, а также осуществляет информационную поддержку и
            продвижение Объединения. Порядок информирования определяется Компанией и доводится до сведения Партнеров и
            Пользователей через Канал связи либо путем опубликования такой информации в Мобильном приложении;
            <br/>Осуществляет мероприятия по развитию Объединения и достижению поставленных целей;
            <br/>Организует рекламное сопровождение и информационное обеспечение деятельности Объединения;
            <br/>Обрабатывает данные для деятельности Объединения;
            <br/>Ведет работу по привлечению новых Партнеров и Пользователей;
            <br/>Обеспечивает выполнение обязательств перед Партнерами и Пользователями, вносит дополнения и изменения в
            Положение и/или договора заключенные с Партнером и/или Пользователем в рамках Объединения;
            <br/>Осуществляет контроль за соблюдением Партнерами и Пользователями Положения и/или договора заключенного
            с Партнером и/или Пользователем в рамках Объединения;
            <br/>Осуществляет информирование Пользователей о специальных предложениях в рамках Объединения посредством
            Канала связи;
            <br/>Осуществляет иные действия, предусмотренные условиями заключенных договоров в рамках Объединения.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Партнер:</p>
            Размещает у себя в офисах, Мобильном приложении, в своих торговых точках символику Объединения по
            договоренности с Компанией;
            <br/>Разъясняет работникам, работающих у него, условия обслуживания Пользователей посредством Мобильного
            приложения;
            <br/>До момента продажи Товара Партнер (работник Партнера) обязан убедиться в наличии у Пользователя
            Мобильного приложения.
            <br/>Надлежащим образом исполняет обязательства по договорам, заключенным в рамках Объединения;
            <br/>Партнер обязан принять к оплате Мобильное приложение Пользователя при приобретении Товара у Партнера;
            <br/>Оплачивает Компании, в случае если такая оплата предусмотрена заключенными договорами;
            <br/>Осуществляет информирование Пользователей о своих специальных предложениях в рамках Объединения
            посредством Канала связи;
            <br/>Осуществляет иные действия, предусмотренные условиями заключенных договоров в рамках Объединения.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Партнеру запрещается:</p>
            Применять/распространять по своему усмотрению персональные данные Пользователя в целях, не связанных с
            деятельностью Объединения;
            <br/>Реализовывать Товары Пользователям по стоимости выше аналогичного Товара, в том числе с учетом скидок
            (акций), приобретаемого с использованием Мобильного приложения по сравнению с другими способами оплаты;
            <br/>Взимать с Пользователей дополнительную плату при расчетах посредством Мобильного приложения за
            реализацию Товара;
            <br/>В рамках реализации Товара выдавать Пользователю деньги взамен Товара;
            <br/>Осуществлять иные действия, предусмотренные условиями заключенных договоров в рамках Объединения.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Пользователь:</p>
            Получает от Компании и/или Партнера информацию о специальных предложениях, представляемых Партнером в
            рамках Объединения посредством Канала связи;
            <br/>Надлежащим образом исполняет обязательства по заключенным договорам в рамках Объединения;
            <br/>В случае необоснованного отказа Партнера в реализации Товара посредством Мобильного приложения
            Пользователь вправе обратиться по данному поводу в Компанию с жалобой в письменной форме либо в электронном
            виде по Каналу связи;
            <br/>По запросу Компании предоставляет документы, подтверждающие покупку Товара (и Товар, при
            необходимости);
            Осуществляет иные действия, предусмотренные условиями заключенных договоров в рамках Объединения.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Пользователю запрещается:</p>
            <br/>Передавать кому-либо мобильное устройство, на котором установлено Мобильное приложение, сообщать
            кому-либо конфиденциальные данные, использованные им при регистрации в Личном кабинете в качестве участника
            Объединения, в том числе Логина и Пароля, необходимых для доступа в Личный кабинет;
            <br/>Брать либо просить от Партнера деньги вместо Товара;
            <br/>Осуществление любых действий, в том числе согласованных с Партнерами, его работниками или иными лицами
            (вступление в сговор), так и самостоятельно, для совершения операций, которые могут быть расценены как
            мошеннические или привести к нарушению Положения и договоров, заключенных в рамках Объединения, в том числе
            с целью мены Баллов на денежные средства (проведение фиктивных операций по Счету Баллов), и иные
            недобросовестные действия, направленные на совершение операций в обход Положения и договоров, заключенных в
            рамках Объединения, получение непредусмотренных Положением и договорами, заключенных в рамках Объединения,
            выгод и преимуществ, в том числе путем совершения притворных, мнимых сделок;
            <br/>Осуществлять иные действия, предусмотренные условиями заключенных договоров в рамках Объединения.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Требования к лицам, участвующим в Объединении</p>
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Требования к Партнерам:</p>
            <br/>Юридическое лицо/индивидуальный предприниматель, реализующие Товар Пользователю в рамках Объединения
            посредством Мобильного приложения;
            <br/>Наличие мобильного устройства, имеющего техническую возможность установить Мобильное приложение;
            <br/>Резиденты Республики Казахстан;
            <br/>Согласие на размещение информации в Мобильном приложении в качестве Партнера;
            <br/>Осуществлять реализацию Товара при оплате Пользователем посредством Мобильного приложения;
            <br/>Иные требования, установленные к Партнеру Объединением и/или договорами, заключаемыми с Партнером в
            рамках Объединения.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Требование к Пользователю:</p>
            Физическое лицо, достигшее восемнадцати лет, не ограниченное в дееспособности;
            <br/>Установка Мобильного приложения на мобильное устройство и его активация Пользователем;
            <br/>Резидент Республики Казахстан.;
            <br/>Иные требования, установленные к Пользователю Объединением и/или договорами, заключаемыми с
            Пользователем в рамках Объединения.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Порядок внесения изменений и дополнений в Положение</p>
            Положение публикуются в Мобильном приложении.
            <br/>Компания вправе в одностороннем порядке вносить изменения в Положение и/или договора, заключаемые с
            Партнером или Пользователем в рамках Объединения. Новая редакция Положения и/или договора, заключенного с
            Партнером или Пользователем в рамках Объединения, с внесенными изменениями размещаются Компанией в Мобильном
            приложении, не менее чем за 30 (тридцать) дней до вступления их в силу.
            <br/>Партнер и/или Пользователь обязуются самостоятельно отслеживать внесение изменений в Положение и/или
            договоры заключенные с Партнером и/или Пользователем в рамках Объединения.
            <br/>Компания осуществляет хранение Положения и договоров заключенных с Партнером и/или Пользователем в
            рамках Объединения, и иных документов (при наличии таковых), а также всех их изменений в течение 3 (трех)
            лет с момента внесения соответствующих изменений.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Порядок выхода из Объединения</p>
            Выход из Объединения регламентирован в договорах, заключаемых Партнером и Пользователем в рамках
            Объединения, при этом выход из Объединения возможен по инициативе Партнера или Пользователя, а также по
            инициативе Компании.
            <br/>Партнер и/или Пользователь может в любое время выйти из Объединения расторгнув договор, заключенный в
            рамках Объединения, и/или в иных случаях в сроки и на условиях установленные договором, заключенном, а
            рамках Объединения.
            <br/>Компания вправе в одностороннем порядке прекратить участив в Объединении Партнера и/или Пользователя по
            своему усмотрению, но в любом случае только при неисполнении и/или ненадлежащем исполнении и/или отказа от
            исполнения Партнером и/или Пользователем условий договоров, заключенных в рамках Объединения.
            <br/>Партнер, при выходе из Объединения, по любым основания, обязан удалить всю символику Объединения,
            удалить соответствующую информацию со своего сайта и рекламных материалов.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Ответственность</p>
            В случае если Партнер/Пользователь злоупотребляет правами, предоставленными в рамках Объединения, тем
            самым нарушая Положение или договоры, заключенные в рамках Объединения, Компания вправе потребовать уплаты
            штрафа в размере, предусмотренном Положением и/или договорами, заключенных в рамках Объединения, а также
            применить иные меры, предусмотренные Положением и договорами, заключенных в рамках Объединения. В данном
            случае, под злоупотреблением следует понимать - недобросовестные действия Партнера и/или Пользователя,
            направленные на неисполнение и ненадлежащее исполнение Положения и/или договоров, заключаемых в рамках
            Объединения.
            <br/>За нарушение Партнером Положения и/или договоров, заключенных в рамках Объединения, Партнер оплачивает
            в пользу Компании штраф (неустойку), в размере определенном договорами, заключенных в рамках Объединения, за
            каждый факт нарушения.
            <br/>За нарушение Пользователем Положения и/или договоров, заключенных в рамках Объединения, Компания вправе
            начислить штраф (неустойку) в размере, определенном договорами, заключенных в рамках Объединения, за каждый
            факт нарушения и списать его со Счета Баллов Пользователя.
            <br/>В случае обнаружения факта необоснованного отказа работниками Партнера обслуживания Пользователя,
            Партнер, помимо уплаты штрафа, обязуется принять меры по предотвращению в дальнейшем отказа, по аналогичным
            обстоятельствам.
            <br/>Партнер, отказавшийся обслуживать Пользователя по предъявленному Мобильному приложению, и не
            мотивировавший при этом свои действия, может быть исключен из участия в Объединении по инициативе Компании.
            <br/>Уплата штрафа (пени) Партнером или Пользователем не освобождает его от исполнения обязательств по
            соответствующему договору.
            <br/>За нарушение Пользователем Положения и/или договоров, заключенных в рамках Объединения, Компания вправе
            к Пользователю, помимо оплаты штрафа вправе применить одно или несколько следующих мер ответственности:
            <br/>Блокировка Мобильного приложения;
            <br/>Отмена начисленных Компанией Баллов;
            <br/>Прекращение участия в Объединении;
            <br/>За нарушение Положения к Партнеру, помимо оплаты штрафа и мер, предусмотренных заключёнными договорами
            в рамках Объединения, может применяться одно или несколько мер ответственности:
            <br/>Прекращение участия в Объединении;
            <br/>Блокировка доступа к Системе и Мобильному приложению.
            <br/>Компания не несет ответственность за любые рода убытки, причиненные Партнеру/Пользователю в результате
            использования третьими лицами учетной и иной информации, ставшей доступной третьим лицам не по вине
            Компании.
            <br/>Партнер/Пользователь несет ответственность за любые действия, осуществленные с использованием
            Мобильного приложения в его Личном кабинете/с использованием Личного кабинета от имени
            Партнера/Пользователя.
            <br/>Пользователь самостоятельно несет ответственность за сохранность мобильного устройства, на котором
            установлено Мобильное приложение, Логина и Пароля от Личного кабинета.
            <br/>Ответственность за качество Товаров и соблюдение всех иных к ним требований, предъявляемых
            законодательством Республики Казахстан, Партнер несет самостоятельно.
            <br/>Компания не несет ответственности за:
            <br/>Перерывы в предоставлении доступа к Системе и Мобильному приложению по причине технических перебоев в
            работе оборудования, программного обеспечения, перебоев в работе Каналов связи, перебоев в электропитании,
            перебоев в работе сети Интернет, сбоев систем маршрутизации и/или в системе доменных имен, осуществления
            DDoS-атак и иных противоправных действий, нарушающих работоспособность интернет-ресурсов и Каналов связи.
            Партнер или Пользователь соглашается с тем, что возможны ошибки и сбои, в том числе в отношении работы
            программного обеспечения/ресурса, обеспечивающих работу Мобильного приложения, при этом Компания будет
            стараться принимать разумные меры для предотвращения таких перебоев, их устранения в разумные сроки;
            <br/>Сбоев в работе Мобильного приложения, в иных сетях телекоммуникаций, вызванных ошибками в коде,
            компьютерными вирусами и иными посторонними фрагментами кода в программном обеспечении Мобильного
            приложения;
            <br/>Неавторизованный (т. е. без разрешения Партнера или Пользователя) доступ третьих лиц к Личному
            кабинету, и иным данным в Мобильном приложении, доступ к которым осуществляется с помощью Логина и Пароля
            Партнера или Пользователя. Ответственность за сохранность любых средств идентификации Партнера или
            Пользователя в Мобильном приложении (в том числе, но не ограничиваясь, Логина и Пароля) является
            обязанностью и ответственностью непосредственно Партнера или Пользователя;
            <br/>Потребительские свойства Товаров, а также за правомерность выставления их на реализацию, за
            достоверность информации, размещаемой Партнером в Мобильном приложении и правомерность ее размещения;
            <br/>Нарушение обязательств по договорам, заключаемым в отношении Товара между Партнером и Пользователями.
            <br/>Неправомерных действий третьих лиц, направленных на нарушение информационной безопасности или
            нормального функционирования Мобильного приложения;
            <br/>Других случаев, связанных с действиями (бездействием) третьих лиц, направленными на ухудшение общей
            ситуации с использованием сети Интернет и/или компьютерного оборудования, существующего в течение срока
            деятельности Объединения, также любых других действий, связанных с работоспособностью Мобильного приложения
            и действия третьих лиц;
            <br/>Выполнения технических и профилактических работ в Мобильном приложении.
            <br/>В случае предоставления Партнером/Пользователем недостоверной, неправильной или неправомерной
            информации, Компания не несет ответственности за содержание размещаемой информации и за возможные
            неблагоприятные последствия, связанные с её (информации) размещением.
            <br/>Незнание Партнером/Пользователем требований Положения не является основанием для непредъявления
            Компанией претензий к Партнеру/Пользователю в рамках Объединения.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Обработка персональных данных</p>
            Присоединяясь к Объединению, Пользователь самостоятельно и по своей инициативе предоставляет Компании и
            Партнерам следующие свои персональные данные: фамилия, имя, отчество, пол, дата рождения, номер мобильного
            устройства, Email, город и улица проживания и иные данные, указанные в Анкете при активации Мобильного
            приложения. Компания и Партнеры имеют право осуществлять автоматизированную и неавтоматизированную обработку
            указанных персональных данных, в том числе их сбор, запись, систематизацию, накопление, хранение, уточнение
            (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение. Пользователь предоставляет согласие на обработку своих
            персональных согласно целям деятельности Объединения.
            <br/>Основной целью обработки предоставленных Пользователем персональных данных является обеспечение участия
            в Объединении, в том числе посредством формирования персональных предложений о продаже Товаров.
            <br/>Пользователь осведомлен и согласен с тем, что обработка предоставленных персональных данных в
            вышеуказанных целях, осуществляется, в том числе, путем передачи (включая предоставление доступа)
            предоставленных персональных данных строго на условиях конфиденциальности и по поручению Объединения
            Партнерам, список которых приведен в Мобильном приложении.
            <br/>Компания обязуется не разглашать персональные данные Пользователей, которые стали ей известны в связи с
            их использованием, за исключением случаев, указанных выше.
            <br/>Согласие Пользователя на обработку персональных данных, предоставленное Компании и Партнерам при
            присоединении к Объединению, действует в течение всего срока деятельности Объединения, если иное не
            предусмотрено действующим законодательством Республики Казахстан, и может быть отозвано Пользователем в
            любой момент путем направления письменного уведомления об отзыве согласия в адрес Компании.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Заключительные положения</p>
            Договора в рамках Объединения с Партнером и/или Пользователем заключаются на неопределенное время
            (являются бессрочными).
            <br/>Компания оставляет за собой право в любое время приостановить (прекратить) деятельность Объединения с
            уведомлением Партнеров и Пользователей за 20 (Двадцать) календарных дней до даты приостановления
            (прекращения), путем направления соответствующего уведомления через Мобильное приложение либо Средством
            связи. В течение периода, на который деятельность Объединения будет приостановлена, а также с даты
            прекращения деятельности Объединения (в случае прекращения деятельности Объединения) Компания не производит
            пополнение, начисление и списание Баллов, а также приостанавливает/прекращает исполнение своих обязательств
            по деятельности Объединения. В случае прекращения деятельности Объединения и неиспользования Пользователями
            Баллов в течение 20 (Двадцати) календарных дней с момента размещения уведомления о прекращении деятельности
            Объединения в Мобильном приложении либо Средством связи, Баллы Пользователей аннулируются в полном объеме,
            при этом Компания и Партнеры не осуществляют никаких выплат и компенсаций в пользу Пользователей.
            <p style={{fontSize: "16px", fontWeight: "bold"}}>Реквизиты компании</p>
            <br/>Наименование: ТОО «Сорпа КЗ»
            <br/>Адрес: Казахстан, город Алматы, Ауэзовский район, Микрорайон 6, дом 58, кв. 23, почтовый индекс A10A6X8
            <br/>БИН: 240440034473
            <br/>КБе: 17
            <br/>Банк: AO «Bereke Bank»
            <br/>Номер счета: K737914032203KZ025AZ, (KZT)
            <br/>Директор: Тулеубаева Ш.У.
            <br/>
            <br/><p style={{fontSize: "16px", fontWeight: "bold"}}>Правила рассмотрения претензий Пользователя в
            отношении Товара</p>
            <br/>Настоящие Правила рассмотрения претензий Пользователя к Товару (далее – Правила) являются неотъемлемой
            частью Положения об Объединении и Договоров, заключаемых в рамках Объединения, и регулируют отношения
            Компании и Партнера при рассмотрении претензий Пользователя в отношении Товара. Претензионный порядок
            рассмотрения споров в отношении Товара определяется Компанией в соответствии с Правилами.
            <br/>Термины и определения:
            <br/>Баллы – виртуальные условные единицы, пополняемые Пользователем на Счет Баллов, начисляемые Компанией
            на Счет Баллов Пользователя, а также списываемые Компанией со Счетов Баллов Пользователя за приобретение
            Товаров у Партнеров. Баллы дают право на приобретение Товара путем обмена Баллов на Товар Партнера. При
            обмене Баллов на Товар Партнера один Балл приравнивается к одному тенге. Баллы используются в учетных целях,
            не являются средством платежа, не могут быть выданы в денежном эквиваленте.
            <br/>Канал связи – система технических средств, с помощью которых Компания и/или Партнер информируют
            (направляют уведомления, объявления, сообщения, непосредственно связанных с функционированием Объединения, а
            также объявления и сообщения рекламного/информационного характера) Пользователей, путем направления
            информации по Email (электронная почта), на мобильное устройство (смартфон, планшет и др.) в виде коротких
            текстовых сообщений (USSD/SMS), Push-уведомлений, в Мобильное приложение, по мессенджеру, телефону, факсу, в
            Личный кабинет.
            <br/>Компания – ТОО «Сорпа КЗ», организует обеспечение взаимодействия между Участниками Объединения,
            определяет Положение об Объединении и условия договоров, заключаемых в рамках Объединения, а также условия
            участия в Объединении. Компания обладает исключительными правами на управление и развитие Объединения.
            <br/>Компания Разработчик – Частная Компания «Clover Ltd.», передавшая право использования в
            предпринимательской деятельности комплекса исключительных прав на мобильное приложение «Sorpa», ТОО «Сорпа
            КЗ» на основании Договора №1 о комплексной предпринимательской лицензии от 16.05.2024г.
            <br/>Мобильное приложение - программное обеспечение Компании, установленное, запущенное на мобильном
            устройстве (смартфон, планшет и др.), предоставляющее доступ Пользователям к услугам Компании и Партнеров по
            реализации Товара.
            <br/>Объединение – союз, включающий в себя Компанию, Пользователей и Партнеров для исполнения условий
            договоров, заключенных в рамках Объединения, предоставляющих возможность Пользователям приобретать у
            Партнеров Товар посредством Мобильного приложения.
            <br/>Партнер – юридическое лицо/индивидуальный предприниматель, реализующие Товар Пользователю в рамках
            Объединения посредством Мобильного приложения.
            <br/>Положение об Объединении (Положение) – документ, определяющий условия участия в Объединении, правила и
            порядок присоединения к Объединению, обслуживания Пользователей в рамках Объединения;
            <br/>Пользователь – физическое лицо, достигшие восемнадцати лет, выразившие добровольное согласие на
            присоединение к Объединению, путем установления Мобильного приложения на мобильное устройство и его
            добровольной активации в предусмотренном Договором порядке, и/или использующего Мобильное приложение для
            приобретения Товара у Партнера. Пользователь предоставляет Компании и Партнерам право хранить и использовать
            свои персональные данные, указываемые Пользователем в Анкете, Заявлениях и иных документах.
            <br/>Товар – товары/услуги/работы не запрещенные и не ограниченные законодательством Республики Казахстан к
            обороту. Ответственность за качество Товаров и соблюдение всех иных к ним требований, предъявляемых
            законодательством Республики Казахстан, возложена на Партнеров, реализующих данные Товары.
            <br/>В целях сохранения лояльности Пользователей Компания вправе принимать участие в рассмотрении
            поступающих от Пользователей в адрес Компании претензий в отношении качества приобретенного Товара (далее –
            Претензия) на условиях Правил.
            <br/>Существо Претензии, в рассмотрении которой вправе участвовать Компания, может относиться к следующему:
            <br/>Выдача Пользователю некачественного Товара (брак, дефект, повреждение упаковки);
            <br/>Выдача Пользователю Товара, не соответствующему информации о Товаре, предоставленной Партнером и
            указанной в Мобильном приложении;
            <br/>Иные случаи по усмотрению Компании.
            <br/>Компания вправе принимать к рассмотрению Претензии, которые на момент получения не были урегулированы
            между Партнером и Пользователем самостоятельно, и содержащие подтверждение Пользователя об отказе от
            исполнения договора купли-продажи в связи с ненадлежащим качеством приобретенного у Партнера Товара.
            <br/>При получении Претензии Компания инициирует и проводит проверку для целей установления обстоятельств. В
            ходе проверки Компания может получать необходимые сведения из доступных источников, в том числе от
            Пользователя и Партнера.
            <br/>При проведении проверки Компания вправе устанавливать (признавать) факты передачи Партнером
            Пользователю Товара ненадлежащего качества либо Товара получившего повреждения, в том числе вследствие
            несоблюдения Партнером требований к упаковке.
            <br/>По результатам проведенной проверки Компания в одностороннем порядке принимает решение об
            обоснованности Претензии и наличии со стороны Партнера нарушений договорных обязательств, в том числе
            Правил.
            <br/>О принятом решении по Претензии Компания уведомляет Партнера и Пользователя посредством Каналов связи.
            <br/>В случае принятия Компанией решения об обоснованности Претензии Пользователя, Партнер обязуется
            возвратить Покупателю Баллы за Товар ненадлежащего качества в связи с отказом Пользователя от исполнения
            договора купли-продажи, и имеет право на возврат Товара.
            <br/>Партнер обязуется удовлетворить требования Компании, указанные в уведомлении, в полном объеме.
            <br/>Компания, принимая участие в рассмотрении Претензий, действует от собственного имени, не представляет
            интересы Пользователя или Партнера и не действует по их поручению.
        </div>
    )
}
